import { css, cx } from '@emotion/css';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';
import { darken } from 'polished';

/* Redux */
import { useSelector } from 'react-redux';

/* Assets */
import vars from 'styles/vars.module.scss';
import thumbsUp from 'assets/images/heart_plus_FILL0.svg';
import thumbsDown from 'assets/images/heart_minus_FILL0.svg';
import thumbsUpFilled from 'assets/images/heart_plus_FILL1.svg';
import thumbsDownFilled from 'assets/images/heart_minus_FILL1.svg';
import arrowRight from 'assets/images/Arrow_Right.svg';
import Close from 'assets/images/Close';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

/* UI Components */
import CareerVideo from './CareerVideo';
import { Tag } from 'shared/components';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const makeStyles = ({ locality }) => ({
  card: css(
    {
      backgroundColor: cssVars.white,
      display: 'grid',
      padding: '25px 24px 36px',
      ...(locality === 'nj' && {
        padding: '25px 24px',
        alignItems: 'center',
        gridGap: 10,
        boxShadow: '0px 2px 10px 0px rgb(0 0 0 / 13%)',
        borderRadius: '4px',
        '@media screen and (min-width: 768px)': {
          gridGap: '5px',
          padding: '24px 24px 15px',
        },
        'h3, .title': {
          marginBottom: 0,
          lineHeight: 'initial',
        },
      }),
      opacity: 1,
      gridTemplateAreas: `"title title title"
                   "likesmatchtags likesmatchtags likesmatchtags"
                   "range range button"`,
      '@media screen and (min-width: 768px)': {
        padding: '24px 24px 21px',
        gridTemplateAreas: `"title title button"
                   "likesmatchtags likesmatchtags range"`,
      },
      position: 'relative',
      maxHeight: '1600px',
    },
    { label: 'card' }
  ),
  card__drawerVisible: css({
    ...(locality === 'nj' && {
      borderRadius: '4px 4px 0 0',
    }),
  }),
  card__hidden: css(
    {
      opacity: 0,
      padding: '0 24px 0 !important',
      transition: 'all .4s',
      transitionTimingFunction: 'ease',
      maxHeight: 0,
      'h3, span': {
        transition: 'all .4s',
        maxHeight: 0,
        transitionTimingFunction: 'ease',
      },
    },
    { label: 'card__hidden' }
  ),
  careerType: css(
    {
      borderLeft: `solid 3px ${cssVars.cardAccentCareer}`,

      gridTemplateAreas: `"title title title"
      "likesmatchtags likesmatchtags likesmatchtags"
      "range range range"
      "button button button"`,
      '@media screen and (min-width: 768px)': {
        gridTemplateAreas: `"title title button"
          "likesmatchtags likesmatchtags range"`,
      },
    },
    { label: 'career-type' }
  ),
  jobType: css(
    {
      borderLeft: `solid 3px ${cssVars.cardAccentJob}`,
      gridTemplateAreas: `"title title title"
      "company company company"
      "likesmatchtags likesmatchtags likesmatchtags"
      "range range range"
      "button button button"`,
      '@media screen and (min-width: 768px)': {
        padding: '24px 24px 21px',
        gridTemplateAreas: `"title title button"
                   "company company range"
                   "likesmatchtags likesmatchtags range"`,
      },
      ...(locality === 'nj' && {
        '@media screen and (min-width: 768px)': {
          padding: '15px 24px 12px',
          gridTemplateAreas: `"title title button"
        "company company range"
        "likesmatchtags likesmatchtags range"`,
        },
      }),
    },
    { label: 'job-type' }
  ),
  jobTypeNoSalary: css(
    {
      gridTemplateAreas: `"title title title"
      "company company company"
      "likesmatchtags likesmatchtags likesmatchtags"
      "button button button"`,
    },
    { label: 'job-type--no-salary' }
  ),
  trainingType: css(
    {
      borderLeft: `solid 3px ${cssVars.cardAccentTraining}`,
      gridTemplateAreas: `"title title title"
        "company company company"
        "likesmatchtags likesmatchtags likesmatchtags"
        "button button button"`,
      '@media screen and (min-width: 768px)': {
        padding: '24px 24px 21px',
        gridTemplateAreas: `"title title button"
                   "company company company"
                   "likesmatchtags likesmatchtags likesmatchtags"`,
      },
    },
    { label: 'training-type' }
  ),
  title: css(
    {
      gridArea: 'title',
      position: 'relative',
      ...(locality === 'nj' && {
        display: 'inline',
        width: 'fit-content',
      }),
      '.soc-info': {
        display: 'none',
      },
    },
    { label: 'title' }
  ),
  titleNoMargin: css(
    {
      marginBottom: '0 !important',
    },
    { label: 'title-no-margin' }
  ),
  titleHoverable: css(
    {
      transition: 'all .35s',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
        color: cssVars.actionBlue,
      },
    },
    { label: 'title-hoverable' }
  ),
  revealSocInfo: css(
    {
      ':hover': {
        '.soc-info': {
          display: 'block',
        },
      },
    },
    { label: 'reveal-soc-info' }
  ),
  infoIcon: css({
    position: 'relative',
    left: '8px',
    top: '1px',
    width: '15px',
    height: '15px',
    transition: 'all .35s',
  }),
  thumbsButton: css({
    width: '20px',
  }),
  filledThumbs: css({
    display: 'none',
  }),
  outlinedThumbs: css({
    display: 'inline',
  }),
  thumbsContainer: css(
    {
      padding: '0 6px',
      position: 'relative',
      '@media screen and (max-width: 767px)': {
        padding: '0 10px',
      },
      '&:first-child': {
        paddingLeft: 0,
        paddingRight: '6px',
        '@media screen and (max-width: 767px)': {
          paddingRight: '10px',
        },
      },
      '&:hover': {
        cursor: 'pointer',
        'img:first-child': {
          //.outlinedThumbs
          display: 'none',
        },
        'img:last-child': {
          //.filledThumbs
          display: 'inline',
        },
      },
      '&:active': {
        top: '1px',
      },
    },
    { label: 'thumbs-container' }
  ),
  thumbsContainerWrapper: css({
    '@media screen and (max-width: 767px)': {
      marginTop: 2,
      marginBottom: 2,
    },
  }),
  thumbsHighlighted: css(
    {
      'img:first-child': {
        //.outlinedThumbs
        display: 'none',
      },
      'img:last-child': {
        //.filledThumbs
        display: 'inline',
      },
    },
    { label: 'thumbs-highlighted' }
  ),
  appliedOverlay: css(
    {
      position: 'absolute',
      left: '-3px',
      background: '#0E6E10',
      opacity: 0,
      pointerEvents: 'none',
      width: 'calc(100% + 3px)',
      height: '100%',
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: cssVars.white,
      transition: 'all .35s ease-in-out',
    },
    { label: 'applied-overlay' }
  ),
  appliedOverlay__visible: css(
    {
      opacity: 0.8,
      pointerEvents: 'all',
    },
    { label: 'applied-overlay__visible' }
  ),
  drawerCloseButton: css(
    {
      width: '25px',
      height: '25px',
      backgroundColor: 'transparent',
      padding: 0,
      border: 'none',
      position: 'absolute',
      top: '27px',
      right: '12px',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: 'transparent',
        top: '28px',
      },
      '@media screen and (max-width: 529px)': css({
        top: '16px',
        '&:active': {
          backgroundColor: 'transparent',
          top: '17px',
        },
      }),
    },
    { label: 'drawer-close-button' }
  ),
  drawerCloseIcon: css(
    {
      fill: `${cssVars.actionBlue} !important`,
      fontSize: '25px !important',
    },
    { label: 'drawer-close-icon' }
  ),
  dotNewJob: css(
    {
      backgroundColor: cssVars.green,
      height: '8px',
      width: '8px',
      borderRadius: '4px',
      position: 'absolute',
      top: '9px',
      left: '-16px',
    },
    { label: 'dot-new-job' }
  ),
  company: css(
    {
      display: 'flex',
      gridArea: 'company',
      flexDirection: 'column',
      ...(locality !== 'nj' && {
        marginBottom: '11px',
      }),
    },
    { label: 'company' }
  ),
  likesMatchTags: css(
    {
      gridArea: 'likesmatchtags',
      display: 'flex',
      flexDirection: 'column',
      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        alignItems: 'center',
        ...(locality !== 'nj' && {
          marginBottom: '-7px',
          alignItems: 'flex-end',
        }),
      },
      ...(locality === 'nj' && {
        marginTop: 4,
      }),
    },
    { label: 'likes-match-tags' }
  ),
  matchText: css(
    {
      position: 'relative',
      bottom: '2px',
      paddingLeft: '18px',
      '&:last-child': {
        paddingLeft: '5px',
        paddingRight: '22px',
      },
    },
    { label: 'match-text' }
  ),
  tags: css(
    {
      display: 'block',
      padding: '20px 0 0',
      marginBottom: '-3px',
      div: {
        position: 'relative',
        top: '-3px',
        marginRight: '8px',
        span: {
          whiteSpace: 'nowrap',
        },
        '@media screen and (max-width: 767px)': {
          marginBottom: '8px',
        },
      },
      '@media screen and (min-width: 768px)': {
        marginBottom: 0,
        padding: '10px 0 0',
      },
    },
    { label: 'tags' }
  ),
  jobsTags: css(
    {
      padding: '20px 0 0',
      '@media screen and (min-width: 768px)': {
        padding: '10px 0 0 12px',
      },
    },
    { label: 'jobs-tags' }
  ),
  button: css(
    {
      gridArea: 'button',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'relative',
      top: '11px',
      '@media screen and (min-width: 768px)': {
        alignItems: 'flex-start',
        top: 0,
      },
      ...(locality !== 'nj' && {
        '@media screen and (max-width: 767px)': {
          justifyContent: 'stretch',
          '&>a, button': {
            marginTop: 0,
            minWidth: '100%',
          },
        },
      }),
      ...(locality === 'nj' && {
        '@media screen and (max-width: 767px)': {
          justifyContent: 'stretch',
          '&>a, button': {
            marginTop: '10px',
            minWidth: '100%',
          },
        },
        '@media screen and (min-width: 768px)': {
          justifyContent: 'flex-end',
          '&>a, button': {
            minWidth: 'auto',
          },
        },
      }),
    },
    { label: 'button' }
  ),
  learnAndApplyButton: css(
    {
      '@media screen and (max-width: 767px)': {
        maxWidth: '134px',
        height: 'fit-content !important',
      },
    },
    { label: 'learn-and-apply-button' }
  ),
  range: css(
    {
      gridArea: 'range',
      display: 'flex',
      position: 'relative',
      top: '7px',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      span: {
        textAlign: 'right',
        '@media screen and (max-width: 767px)': {
          textAlign: 'left',
        },
      },
    },
    { label: 'range' }
  ),
  rangeLabel: css(
    {
      '@media screen and (min-width: 768px)': {
        paddingLeft: '6px',
      },
    },
    { label: 'range-label' }
  ),
  arrowRight: css(
    {
      width: '15px',
      position: 'relative',
      top: '1px',
    },
    { label: 'arrow-right' }
  ),
  flag: css(
    {
      fontSize: '10px',
      lineHeight: '15px',
      fontWeight: '700',
      textTransform: 'uppercase',
      display: 'inline-block',
      padding: '4px 14px',
      position: 'absolute',
      transform: 'translate(-3px, -50%)',
      zIndex: '2',
      '@media screen and (min-width: 768px)': {
        transform: 'translate(-19px, -50%)',
      },
      ...(locality === 'nj' && {
        alignSelf: 'flex-start',
      }),
    },
    { label: 'flag' }
  ),
  careerFlag: css(
    {
      color: cssVars.white,
      backgroundColor: cssVars.cardAccentCareer,
    },
    { label: 'career-flag' }
  ),
  jobFlag: css(
    {
      color: cssVars.darkGray,
      backgroundColor: cssVars.cardAccentJob,
    },
    { label: 'job-flag' }
  ),
  trainingFlag: css(
    {
      color: cssVars.darkGray,
      backgroundColor: cssVars.cardAccentTraining,
    },
    { label: 'training-flag' }
  ),
  flagCorner: css(
    {
      display: 'none',
      '@media screen and (min-width: 768px)': {
        display: 'inline-block',
        zIndex: 1,
        width: '19px',
        height: '14px',
        position: 'absolute',
        transform: 'rotate(33deg) translateX(-11px) translateY(12px)',
      },
      '&:after': {
        content: `' '`,
        display: 'inline-block',
        width: '19px',
        height: '19px',
        backgroundColor: cssVars.white,
        transform: 'rotate(-33deg) translateX(17px)',
      },
      ...(locality === 'nj' && {
        alignSelf: 'flex-start',
      }),
    },
    { label: 'flag-corner' }
  ),
  careerFlag__Corner: css(
    {
      backgroundColor: darken(0.1, cssVars.cardAccentCareer),
    },
    { label: 'career-flag__corner' }
  ),
  jobFlag__Corner: css(
    {
      backgroundColor: darken(0.1, cssVars.cardAccentJob),
    },
    { label: 'job-flag__corner' }
  ),
  trainingFlag__Corner: css(
    {
      backgroundColor: darken(0.1, cssVars.cardAccentTraining),
    },
    { label: 'training-flag__corner' }
  ),
  margin: css(
    {
      marginBottom: '40px',
    },
    { label: 'margin' }
  ),
  margin__hidden: css(
    {
      marginBottom: 0,
      transition: 'all .4s',
    },
    { label: 'margin__hidden' }
  ),
  drawer: css(
    {
      backgroundColor: cssVars.white,
      borderTop: `solid 1px ${cssVars.mediumGray}`,
      padding: '30px 20px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      transition: 'all .55s',
      ...(locality === 'nj' && {
        borderRadius: '0 0 4px 4px',
        boxShadow: '0px 2px 10px 0px rgb(0 0 0 / 13%)',
      }),
      '@media screen and (min-width: 768px)': {
        padding: '32px 73px',
      },
    },
    { label: 'drawer' }
  ),
  drawer__visible: css(
    {
      maxHeight: '1800px',
      opacity: 1,
    },
    { label: 'drawer__visible' }
  ),
  drawer__hidden: css(
    {
      borderTopColor: 'transparent',
      maxHeight: 0,
      padding: '0 20px',
      opacity: 0,
      '@media screen and (min-width: 768px)': {
        padding: '0 73px',
      },
    },
    { label: 'drawer__hidden' }
  ),
  careerDrawer: css(
    {
      borderLeft: `solid 3px ${cssVars.cardAccentCareer}`,
    },
    { label: 'career-drawer' }
  ),
  jobDrawer: css(
    {
      borderLeft: `solid 3px ${cssVars.cardAccentJob}`,
    },
    { label: 'job-drawer' }
  ),
  trainingDrawer: css(
    {
      borderLeft: `solid 3px ${cssVars.cardAccentTraining}`,
    },
    { label: 'training-drawer' }
  ),
  videoContainer: css(
    {
      margin: '32px auto',
      video: {
        width: '240px',
        '@media screen and (min-width: 530px)': {
          width: '400px',
        },
        '@media screen and (min-width: 768px)': {
          width: '640px',
        },
      },
    },
    { label: 'video-container' }
  ),
  videoContainer__noVideo: css(
    {
      margin: '12px auto',
    },
    { label: 'video-container' }
  ),
  drawerBottomSection: css(
    {
      marginTop: '20px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '20px',
      '@media screen and (max-width: 767px)': {
        display: 'flex',
        flexDirection: 'column',
        'div:first-child': {
          paddingBottom: '18px',
        },
      },
    },
    { label: 'drawer-bottom-section' }
  ),
  link: css(
    {
      color: cssVars.actionBlue,
      fontWeight: 700,
      textDecoration: 'underline',
      '&:visited': {
        color: cssVars.actionBlue,
      },
      '&:hover, &:active': {
        color: darken(0.1, cssVars.actionBlue),
        cursor: 'pointer',
      },
    },
    { label: 'link' }
  ),
  linkWhite: css(
    {
      color: cssVars.white,
      fontWeight: 700,
      textDecoration: 'underline',
      '&:visited': {
        color: cssVars.white,
      },
      '&:hover, &:active': {
        color: darken(0.1, cssVars.white),
        cursor: 'pointer',
      },
    },
    { label: 'link-white' }
  ),
  descriptor: css(
    {
      fontSize: '14px',
      lineHeight: '21px',
      color: cssVars.darkGray,
    },
    { label: 'descriptor' }
  ),
  descriptorBold: css(
    {
      fontSize: '14px',
      lineHeight: '21px',
      color: cssVars.darkGray,
      fontWeight: 700,
    },
    { label: 'descriptor-bold' }
  ),
  bold: css(
    {
      fontWeight: '700',
    },
    { label: 'bold' }
  ),
  caretIcon: css(
    {
      position: 'absolute',
      bottom: -4,
    },
    { label: 'caret' }
  ),
});

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: vars.white,
    color: vars.black,
    fontSize: 13,
    borderRadius: 0,
    position: 'absolute',
    fontStyle: 'italic',
    left: '-10px',
    width: '360px',
    maxWidth: '620px',
    '@media (min-width: 640px)': {
      width: '580px',
      fontSize: 14,
    },
  },
  arrow: {
    color: vars.actionBlue,
  },
}))(Tooltip);

function newTab(url) {
  window.open(url, '_blank');
}

const RecommendationsCard = ({
  item,
  rating,
  type,
  link,
  rangeLabel,
  titleHoverable = false,
  ratingsPage = false,
  careersDict = {},
  onButtonClick = () => {},
  onTitleClick = () => {},
  onLikeClick = () => {},
  onDislikeClick = () => {},
  onTagClick = () => {},
  hideRateTooltip = false,
  showOverlay = false,
  showShadow = false,
}) => {
  // For 'topjob' types, we tag various elements with CSS classes.
  // These classes will get used during server-side rendering to locat elements and
  // fill in the placeholders with real data.

  const locality = process.env.REACT_APP_LOCALITY;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const styles = makeStyles({ locality, type });

  const user_settings = useSelector((state) => state.app.user_settings);
  const ratingsLookup = useSelector((state) => state.ratings.results.lookup);
  const ratingsIds = Object.keys(ratingsLookup).filter(
    (id) => id.split('#')[0] !== 'skills' && typeof ratingsLookup[id] !== 'undefined'
  );

  const [expandCard, setExpandCard] = useState(false);
  const ref = useRef();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const jaOrzh = ['ja', 'zh'].includes(intl.locale);
  let history = useHistory();
  const flagsDict = {
    career: 'careers',
    job: 'jobs',
  };

  //show user which jobs they've already "applied" to in this browser session
  const wasApplyClickedRef = useRef(false);
  const overlayTimerRef = useRef(null);
  const [showAppliedOverlay, setShowAppliedOverlay] = useState(0);
  const [shownAppliedOverlay, setShownAppliedOverlay] = useState(false);
  const [shownOnHover, setShownOnHover] = useState(false);
  useEffect(() => {
    if (showAppliedOverlay) {
      overlayTimerRef.current = setTimeout(() => {
        setShowAppliedOverlay(0);
        if (shownOnHover) {
          setShownAppliedOverlay(true);
        }
      }, showAppliedOverlay);
    }

    return () => {
      clearTimeout(overlayTimerRef.current);
    };
  }, [showAppliedOverlay]);

  const handleApplyClick = async (e) => {
    if (link) {
      newTab(link);
    } else if (item.apply) {
      newTab(item.apply);
    } else if (item.link) {
      newTab(item.link);
    }
    const response = await onButtonClick(e);
    if (['job', 'topjob'].includes(type) && response !== 'not_created') {
      wasApplyClickedRef.current = true;
      setShowAppliedOverlay(6000);
    }
  };
  const handleAppliedHover = () => {
    if (wasApplyClickedRef.current && !showAppliedOverlay) {
      setShowAppliedOverlay(3000);
      setShownOnHover(true);
    }
  };

  const ThumbsWrapper = ({ msg, children }) => {
    return ratingsIds.length > 0 || hideRateTooltip || ratingsPage ? (
      <>{children}</>
    ) : (
      <HtmlTooltip arrow title={msg}>
        {children}
      </HtmlTooltip>
    );
  };
  ThumbsWrapper.propTypes = {
    msg: PropTypes.string,
    children: PropTypes.any,
  };

  const handleTitleClick = () => {
    onTitleClick();
    if (titleHoverable) {
      setExpandCard(!expandCard);
    }
  };
  const handleTitleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleClick();
    }
  };

  const handleKeypress = (e, cb) => {
    if (e.key === 'Enter') {
      cb();
    }
  };

  let showNewJobDot = false;
  if (['job', 'topjob'].includes(type)) {
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = yesterday.toISOString().replace(/-/g, '').split('T')[0];
    showNewJobDot = item.date_posted === yesterday;
  }

  const hasSalaryInfo = !!item.salary_low && !!item.salary_high && ['career', 'job', 'topjob'].includes(type);
  const showSalaryRange =
    hasSalaryInfo &&
    ((type === 'career' && ['nj', 'co', 'hi', 'md', 'wi'].includes(locality)) || ['job', 'topjob'].includes(type));

  const handleHeroButtonClick = (path) => {
    if (path === 'job-search-activity') {
      trackEvent('JOBS_JOBSEARCH_ACTIVITY');
    }
    history.push(`${urlPrefix}/${path}`);
  };

  return (
    <>
      <div
        ref={ref}
        onMouseEnter={handleAppliedHover}
        className={cx({
          [styles.card]: true,
          [styles.careerType]: type === 'career',
          [styles.jobType]: ['job', 'topjob'].includes(type),
          [styles.jobTypeNoSalary]: ['job', 'topjob'].includes(type) && !hasSalaryInfo,
          [styles.trainingType]: type === 'training',
          [styles.card__hidden]: !ratingsPage && rating === 'disliked',
          [styles.card__drawerVisible]: expandCard,
          prerender_top_job_card: type === 'topjob',
        })}
        style={{ boxShadow: showShadow ? '0px 3px 12px rgba(0,0,0,0.1)' : 'none' }}
      >
        {type === 'job' && showOverlay ? (
          <div
            className={cx({
              [styles.appliedOverlay]: true,
              [styles.appliedOverlay__visible]:
                user_settings.job_search_activity_apply_confirmation_banner &&
                showAppliedOverlay &&
                !shownAppliedOverlay,
            })}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <CheckIcon /> <span style={{ paddingLeft: 5 }}>{intl.formatMessage({ id: 'jobs.applied' })}</span>
            </span>
            <p style={{ margin: 7, padding: 16, textAlign: 'center' }}>
              {intl.formatMessage(
                { id: 'jobs.beenAdded' },
                {
                  link: (
                    <span className={styles.linkWhite} onClick={() => handleHeroButtonClick('job-search-activity')}>
                      {intl.formatMessage({ id: 'jobs.jobSearchActivity' })}
                    </span>
                  ),
                }
              )}
              {locality === 'ar' ? (
                <>
                  <br />
                  {intl.formatMessage(
                    { id: 'jobs.beenAdded2' },
                    {
                      link: (
                        <span className={styles.linkWhite} onClick={() => handleHeroButtonClick('job-search-activity')}>
                          {intl.formatMessage({ id: 'jobs.jobSearchActivity' })}
                        </span>
                      ),
                    }
                  )}
                </>
              ) : null}
            </p>
          </div>
        ) : null}
        {item.flag && !((ratingsPage || item.rating) && item.flag === 'rateThis') && (
          <>
            <div
              className={cx({
                [styles.flag]: true,
                [styles.careerFlag]: type === 'career',
                [styles.jobFlag]: type === 'job',
                [styles.trainingFlag]: type === 'training',
              })}
            >
              {intl.formatMessage({ id: `${flagsDict[type]}.flags.${item.flag}`, description: 'string' })}
            </div>
            <span
              className={cx({
                [styles.flagCorner]: true,
                [styles.careerFlag__Corner]: type === 'career',
                [styles.jobFlag__Corner]: type === 'job',
                [styles.trainingFlag__Corner]: type === 'training',
              })}
            />
          </>
        )}
        {titleHoverable && type === 'career' ? (
          <div
            className={cx({
              [styles.title]: true,
            })}
          >
            <Typography
              className={cx({
                [styles.titleNoMargin]: ['job', 'topjob', 'training'].includes(type),
                [styles.titleHoverable]: titleHoverable,
                prerender_top_job_title: type === 'topjob',
              })}
              style={{ display: 'inline-block' }}
              variant="h4"
              component="h3"
              gutterBottom
              onClick={handleTitleClick}
              onKeyUp={titleHoverable ? handleTitleKeyPress : () => {}}
              tabIndex={titleHoverable ? 0 : null}
            >
              {showNewJobDot ? <div className={styles.dotNewJob} /> : null}
              {`title_${intl.locale}` in item && item[`title_${intl.locale}`].trim() !== ''
                ? item[`title_${intl.locale}`]
                : item.title}
              {expandCard ? (
                <KeyboardArrowUpIcon className={styles.caretIcon} sx={{ fontSize: 22, top: 5, position: 'relative' }} />
              ) : (
                <KeyboardArrowDownIcon
                  className={styles.caretIcon}
                  sx={{ fontSize: 22, top: 5, position: 'relative' }}
                />
              )}
            </Typography>
          </div>
        ) : (
          <Typography
            className={cx({
              [styles.title]: true,
              [styles.titleHoverable]: titleHoverable,
              [styles.revealSocInfo]: ['dev', 'qa'].includes(environment) && type === 'job',
              [styles.titleNoMargin]: ['job', 'topjob', 'training'].includes(type),
              prerender_top_job_title: type === 'topjob',
            })}
            variant="h4"
            component="h3"
            gutterBottom
            onClick={handleTitleClick}
            onKeyUp={titleHoverable ? handleTitleKeyPress : () => {}}
            tabIndex={titleHoverable ? 0 : null}
          >
            {showNewJobDot ? <div className={styles.dotNewJob} /> : null}
            {`title_${intl.locale}` in item && item[`title_${intl.locale}`]?.trim() !== ''
              ? item[`title_${intl.locale}`]
              : item.title}{' '}
            {['dev', 'qa'].includes(environment) && type === 'job' ? (
              <span className="soc-info">
                [{item.soc ? item.soc : item.soc_code} - {careersDict[item.soc ? item.soc : item.soc_code]}]
              </span>
            ) : null}
          </Typography>
        )}
        {item.org && type !== 'career' && (
          <div className={cx({ [styles.company]: true, prerender_company: true })}>
            <span
              className={cx({
                [styles.descriptor]: true,
                prerender_top_job_org: type === 'topjob',
              })}
              style={{ fontSize: 15, color: '#121212', fontWeight: 500, marginBottom: 3 }}
            >
              {item.org}
            </span>
            <span
              className={cx({
                [styles.descriptor]: true,
                prerender_top_job_location: type === 'topjob',
              })}
            >
              <b style={{ opacity: 0.96 }}>{intl.formatMessage({ id: 'training.filters.city' })}:</b>{' '}
              {locality === 'nj'
                ? item.counties
                : item.city
                ? item.city
                : intl.formatMessage({ id: 'training.notProvided' })}
            </span>
          </div>
        )}
        {type === 'training' && locality !== 'co' && (
          <div className={styles.company}>
            <span className={cx(styles.descriptorBold)}>
              {item.duration ? intl.formatMessage({ id: 'training.length' }) : ''}{' '}
              {item.duration && `duration_units_${intl.locale}` in item
                ? `${item.duration} ${item[`duration_units_${intl.locale}`]}`
                : trainingsDurationTextFormatter(intl, item.duration)}
            </span>
            <span className={cx(styles.descriptor)}>
              <b style={{ opacity: 0.96 }}>{intl.formatMessage({ id: 'training.filters.city' })}:</b>{' '}
              {item.city ? item.city : intl.formatMessage({ id: 'training.notProvided' })}
            </span>
            <span className={cx(styles.descriptor)}>
              <b style={{ opacity: 0.96 }}>{intl.formatMessage({ id: 'training.filters.county' })}:</b>{' '}
              {item.county ? item.county : intl.formatMessage({ id: 'training.notProvided' })}
            </span>
            <span className={cx(styles.descriptor)}>
              <b style={{ opacity: 0.96 }}>{intl.formatMessage({ id: 'training.provider' })}:</b>{' '}
              {item.training_provider ? item.training_provider : intl.formatMessage({ id: 'training.notProvided' })}
            </span>
          </div>
        )}
        {type === 'training' && locality === 'co' && (
          <div className={styles.company}>
            <span className={cx(styles.descriptorBold)}>
              {item.duration ? intl.formatMessage({ id: 'training.length' }) : ''}{' '}
              {item.duration && `duration_units_${intl.locale}` in item
                ? `${item.duration} ${item[`duration_units_${intl.locale}`]}`
                : trainingsDurationTextFormatter(intl, item.duration)}
            </span>
            {/* <span className={cx(
            styles.descriptor,
          )}>
            {intl.formatMessage({id: 'training.location'})} {item.location}
          </span> */}
            {/* CSB-1426: temporary for CO while they figure out weeks vs credits. remove code below and return code above */}
            <span>
              <span className={cx(styles.descriptorBold)}>{intl.formatMessage({ id: 'training.location' })}</span>{' '}
              {item.county ? item.county : item.city ? item.city : item.location}{' '}
            </span>
          </div>
        )}
        <div className={cx({ [styles.likesMatchTags]: true, prerender_likes_match_tags: type === 'topjob' })}>
          {!['topjob'].includes(type) && (
            <div className={styles.thumbsContainerWrapper}>
              {ratingsPage && rating === 'disliked' ? (
                <ThumbsWrapper msg={intl.formatMessage({ id: 'recommendations.card.newThumbsDown' })}>
                  <span
                    className={styles.link}
                    style={{ position: 'relative', top: -5 }}
                    tabIndex={0}
                    onKeyUp={(e) => handleKeypress(e, onDislikeClick)}
                    onClick={onDislikeClick}
                  >
                    {intl.formatMessage({ id: 'ratings.showAgain' })}
                    <UndoIcon style={{ fontSize: 20, position: 'relative', top: 5, marginLeft: 2, marginRight: 1 }} />
                  </span>
                </ThumbsWrapper>
              ) : null}
              <ThumbsWrapper msg={intl.formatMessage({ id: 'recommendations.card.newThumbsUp' })}>
                <span
                  className={cx({ [styles.thumbsContainer]: true, [styles.thumbsHighlighted]: rating === 'liked' })}
                  tabIndex={0}
                  onKeyUp={(e) => handleKeypress(e, onLikeClick)}
                  onClick={onLikeClick}
                >
                  <img alt="Thumbs Up" src={thumbsUp} className={cx(styles.thumbsButton, styles.outlinedThumbs)} />
                  <img alt="Thumbs Up" src={thumbsUpFilled} className={cx(styles.thumbsButton, styles.filledThumbs)} />
                </span>
              </ThumbsWrapper>
              {!ratingsPage || rating === 'liked' ? (
                <ThumbsWrapper msg={intl.formatMessage({ id: 'recommendations.card.newThumbsDown' })}>
                  <span
                    className={cx({
                      [styles.thumbsContainer]: true,
                      [styles.thumbsHighlighted]: rating === 'disliked',
                    })}
                    tabIndex={0}
                    onKeyUp={(e) => handleKeypress(e, onDislikeClick)}
                    onClick={onDislikeClick}
                  >
                    <img
                      alt="Thumbs Down"
                      src={thumbsDown}
                      className={cx(styles.thumbsButton, styles.outlinedThumbs)}
                    />
                    <img
                      alt="Thumbs Down"
                      src={thumbsDownFilled}
                      className={cx(styles.thumbsButton, styles.filledThumbs)}
                    />
                  </span>
                </ThumbsWrapper>
              ) : null}
              {item.match_rating && (
                <>
                  <span
                    className={cx({
                      [styles.descriptor]: true,
                      [styles.bold]: true,
                      [styles.matchText]: true,
                      prerender_match_text: type === 'topjob',
                    })}
                  >
                    {item.match_rating}%
                  </span>
                  <span
                    className={cx({
                      [styles.descriptor]: true,
                      [styles.matchText]: true,
                      prerender_match_text: type === 'topjob',
                    })}
                  >
                    {intl.formatMessage({ id: 'careers.match', description: 'string' })}
                  </span>
                </>
              )}
            </div>
          )}
          <div
            className={cx({
              [styles.tags]: type !== 'job' && item.tags && item.tags.length,
              prerender_tags: type === 'topjob',
            })}
          >
            {item.tags &&
              type !== 'job' &&
              item.tags.map((t) => {
                let text = `jobs.filters.${t}`;
                if (type === 'career') {
                  text = `careers.tags.${t}`;
                }
                return (
                  <Tag
                    clickable={type === 'career' ? false : true}
                    key={t}
                    text={intl.formatMessage({ id: text, description: 'string' })}
                    type={type}
                    onClick={onTagClick}
                  />
                );
              })}
          </div>
        </div>
        <div className={cx({ [styles.button]: true, prerender_button: type === 'topjob' })}>
          {type === 'career' ? (
            <Button
              color={locality === 'nj' ? 'primary' : 'secondary'}
              size="large"
              type="button"
              variant={locality === 'nj' ? 'outlined' : 'contained'}
              onClick={onButtonClick}
            >
              <span>{item.available_jobs}</span>
              <span>
                &nbsp;
                {intl.formatMessage({
                  id:
                    'available_jobs' in item && parseInt(item.available_jobs, 10) > 1 ? 'careers.jobs' : 'careers.job',
                  description: 'string',
                })}
                &nbsp;
                <img alt="Arrow right" src={arrowRight} className={cx([styles.arrowRight], 'prerender_arrow_right')} />
              </span>
            </Button>
          ) : (
            <Button
              color={locality === 'nj' ? 'primary' : 'secondary'}
              size="large"
              type="button"
              variant={locality === 'nj' ? 'outlined' : 'contained'}
              onClick={handleApplyClick}
              className={cx({
                [styles.learnAndApplyButton]: true,
                prerender_top_job_apply: type === 'topjob',
              })}
              aria-label={intl.formatMessage({ id: 'jobs.learnAndApply' })}
            >
              <span>
                &nbsp;
                {intl.formatMessage({
                  id: locality === 'ar' && type === 'training' ? 'training.cta' : 'jobs.learnAndApply',
                })}
                &nbsp;
                {locality === 'ar' ? (
                  <OpenInNewIcon style={{ fontSize: 16, position: 'relative', top: 3, marginLeft: 2 }} />
                ) : (
                  <img
                    alt="Arrow right"
                    src={arrowRight}
                    className={cx({ [styles.arrowRight]: true, prerender_arrow_right: type === 'topjob' })}
                  />
                )}
              </span>
            </Button>
          )}
        </div>
        {showSalaryRange && (
          <div className={cx({ [styles.range]: true, prerender_range: type === 'topjob' })}>
            <span
              className={cx({
                [styles.descriptor]: true,
                [styles.rangeLabel]: true,
                prerender_range_label: type === 'topjob',
              })}
            >
              {rangeLabel}
            </span>
            <span
              className={cx({
                [styles.descriptor]: true,
                [styles.bold]: true,
                prerender_top_job_salary: type === 'topjob',
              })}
            >
              {`$${jaOrzh ? (item.salary_low / 10000).toFixed(1) : Math.floor(item.salary_low / 1000)}${
                jaOrzh ? '万' : 'K'
              }${item.salary_low !== item.salary_high ? ' - $' : ''}${
                item.salary_low !== item.salary_high
                  ? jaOrzh
                    ? (item.salary_high / 10000).toFixed(1)
                    : Math.floor(item.salary_high / 1000)
                  : ''
              }${item.salary_low !== item.salary_high ? (jaOrzh ? '万' : 'K') : ''} / ${intl.formatMessage({
                id: 'careers.year',
              })}`}
            </span>
          </div>
        )}
      </div>
      {titleHoverable && (ratingsPage || rating !== 'disliked') ? (
        <div
          className={cx({
            [styles.drawer]: true,
            [styles.careerDrawer]: type === 'career',
            [styles.jobDrawer]: type === 'job',
            [styles.trainingDrawer]: type === 'training',
            [styles.drawer__visible]: expandCard,
            [styles.drawer__hidden]: !expandCard,
          })}
        >
          <button
            title={intl.formatMessage({ id: 'careers.closeCareersDetails' })}
            className={styles.drawerCloseButton}
            onClick={() => setExpandCard(false)}
          >
            <Close className={styles.drawerCloseIcon} />
          </button>
          {type === 'career' && item.common_skills ? (
            <div>
              <Typography variant="h5" component="span" gutterBottom>
                {intl.formatMessage({ id: 'recommendations.card.commonSkills', description: 'string' })}
              </Typography>
              {': '}
              <Typography variant="body2" component="span" gutterBottom>
                {item.common_skills.length ? `${item.common_skills.join(', ')}.` : ''}
              </Typography>
            </div>
          ) : null}
          <div className={item.video ? styles.videoContainer : styles.videoContainer__noVideo}>
            {expandCard && (
              <CareerVideo
                title={`title_${intl.locale}` in item ? item[`title_${intl.locale}`] : item.title}
                soc_code={item.soc_code}
                url={item.video}
              />
            )}
          </div>
          <Typography variant="body2" component="p" gutterBottom>
            {item.description}
          </Typography>
          <div className={styles.drawerBottomSection}>
            <div>
              <Typography variant="h5" component="span" gutterBottom>
                {intl.formatMessage({ id: 'recommendations.card.typicalTraining', description: 'string' })}
              </Typography>
              {': '}
              <Typography variant="body2" component="div" gutterBottom>
                {item.typical_training}
              </Typography>
            </div>
          </div>
        </div>
      ) : null}

      <div
        className={cx({
          [styles.margin]: true,
          [styles.margin__hidden]: !ratingsPage && rating === 'disliked',
          prerender_margin: type === 'topjob',
        })}
      />
    </>
  );
};

RecommendationsCard.propTypes = {
  item: PropTypes.object,
  rating: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  rangeLabel: PropTypes.string,
  titleHoverable: PropTypes.bool,
  ratingsPage: PropTypes.bool,
  careersDict: PropTypes.object,
  onButtonClick: PropTypes.func,
  onTitleClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  onDislikeClick: PropTypes.func,
  onTagClick: PropTypes.func,
  hideRateTooltip: PropTypes.bool,
  showOverlay: PropTypes.bool,
  showShadow: PropTypes.bool,
};

export default RecommendationsCard;

export const trainingsDurationTextFormatter = (intl, d) => {
  // For NJ
  const valueMap = {
    0.14: `${intl.formatMessage({ id: 'training.duration.lessThan' })} a ${intl.formatMessage({
      id: 'training.duration.day',
    })}`,
    0.21: `1-2 ${intl.formatMessage({ id: 'training.duration.days' })}`,
    0.71: `3-7 ${intl.formatMessage({ id: 'training.duration.days' })}`,
    3: `2-3 ${intl.formatMessage({ id: 'training.duration.weeks' })}`,
    10: `4-11 ${intl.formatMessage({ id: 'training.duration.weeks' })}`,
    15: `3-5 ${intl.formatMessage({ id: 'training.duration.months' })}`,
    35: `6-12 ${intl.formatMessage({ id: 'training.duration.months' })}`,
    100: `13 ${intl.formatMessage({ id: 'training.duration.months' })} - 2 ${intl.formatMessage({
      id: 'training.duration.years',
    })}`,
    200: `3-4 ${intl.formatMessage({ id: 'training.duration.years' })}`,
    300: `${intl.formatMessage({ id: 'training.duration.moreThan' })} 4 ${intl.formatMessage({
      id: 'training.duration.years',
    })}`,
  };

  if (!d) {
    return '';
  }
  if (typeof d === 'string' || typeof d === 'number') {
    if (d === 'varies') {
      return intl.formatMessage({ id: 'training.varies' });
    }
    // in case HI data comes through here (`${num} weeks`)
    if (d > 3) {
      const newDuration = Math.floor(d / 4);
      return `${newDuration} ${intl.formatMessage({
        id: `training.duration.${newDuration === 1 ? 'month' : 'months'}`,
      })}`;
    }
    if (d <= 1) {
      return `<1 ${intl.formatMessage({ id: `training.duration.week` })}`;
    }
    if (typeof d === 'string' && d.includes('-')) {
      const [min, max] = d.split('-');
      const newMin = Math.floor(min / 4);
      const newMax = Math.floor(max / 4);
      return `${newMin}-${newMax} ${intl.formatMessage({ id: 'training.duration.months' })}`;
    }
    return `${d} ${intl.formatMessage({ id: `training.duration.weeks` })}`;
  } else {
    return valueMap[Object.keys(valueMap).find((key) => key >= d.min)] || valueMap[300];
  }
};
