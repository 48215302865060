import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import { apiRequest } from 'lib/API';

import { PhoneNumberInput } from 'shared/components';

import { DatePicker } from '@mui/x-date-pickers';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* i18n */
import { useIntl } from 'react-intl';

import styles from 'styles/apply-job-form.module.scss';

import BrowseDocuments from 'components/BrowseDocuments';

function Resea({
  addActivityItem,
  updateActivityItem,
  setGeneralActivity,
  setOpen,
  selectedActivity,
  editting = false,
  week,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [refreshConter, setRefreshConter] = useState(0);
  const [documentsLinks, setDocumentsLinks] = useState([]);
  const [dateError, setDateError] = useState('');
  const intl = useIntl();

  useEffect(() => {
    setRefreshConter(refreshConter + 1);
    if (selectedActivity.upload_documents && selectedActivity.upload_documents.length > 0) {
      setDocumentsLinks([...selectedActivity.upload_documents]);
    }
  }, [selectedActivity, editting]);

  useEffect(() => {
    const documentsWithNoLinks = documentsLinks.filter((d) => !d.downloadUrl);
    if (documentsWithNoLinks.length > 0) {
      documentsLinks.forEach((doc, idx) => {
        if (doc.downloadUrl) {
          return;
        }
        apiRequest(
          'get',
          `/users/me/worksearch/${encodeURIComponent(selectedActivity.worksearch_id)}?file_uuid=${encodeURIComponent(
            doc.file_uuid
          )}`
        ).then((res) => {
          setDocumentsLinks([
            ...documentsLinks.slice(0, idx),
            { ...doc, downloadUrl: res.activity?.download_url },
            ...documentsLinks.slice(idx + 1),
          ]);
        });
      });
    }
  }, [JSON.stringify(documentsLinks)]);

  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // eslint-disable-line no-useless-escape
  const emailRegExp = /^\S+@\S+\.\w\w+$/;

  const validationSchema = yup.object({
    contact_name: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    contact_phone_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(phoneRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    contact_email: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(emailRegExp, intl.formatMessage({ id: 'forms.onboarding.emailValidation' })),
    date_of_workshop: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
  });

  const defaultValues = {
    contact_name: '',
    contact_phone_number: '',
    contact_email: '',
    date_of_workshop: null,
    notes: '',
    upload_documents: [],
    draft: false,
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={
        selectedActivity
          ? {
              ...selectedActivity,
              upload_documents: [],
              date_of_workshop: new Date(
                selectedActivity.date_of_workshop.slice(0, 4),
                selectedActivity.date_of_workshop.slice(5, 7) - 1,
                selectedActivity.date_of_workshop.slice(8, 10)
              ),
            }
          : defaultValues
      }
      onSubmit={async (values) => {
        setSubmitting(true);
        let year = values.date_of_workshop.getFullYear();
        let month = values.date_of_workshop.getMonth() + 1;
        let day = values.date_of_workshop.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        const vals4submit = {
          ...values,
          activity_type: 'participate_in_resea',
          activity_date: `${year}-${month}-${day}`,
          date_of_workshop: `${year}-${month}-${day}`,
        };
        vals4submit.upload_documents = values.upload_documents.map((doc) => ({
          filename: doc.name,
          upload_contents: doc.contents,
        }));
        if (editting) {
          trackEvent('WEEK_VIEWACTIVITY_SAVE');
        } else {
          trackEvent('WEEK_SAVEACTIVITY_RESEA');
        }
        apiRequest(editting ? 'put' : 'post', '/users/me/worksearch/', { data: { activity: vals4submit } })
          .then(({ activity, status }) => {
            if (editting) {
              updateActivityItem(activity);
            } else {
              addActivityItem(activity);
            }
            setGeneralActivity(status);
            setOpen(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }
        const handleDateChange = (date) => {
          const minDate = new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10));
          const maxDate = new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10));
          if (isValidDate(date)) {
            setFieldValue('date_of_workshop', date);
            if (date < minDate || date > maxDate) {
              setDateError('The selected date is outside the valid range');
            } else {
              setDateError('');
            }
          } else {
            let error = intl.formatMessage({ id: 'jobSearchActivity.validation.invalidDateFormat' });
            if (Object.is(date, null)) {
              error = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            setDateError(error);
            setFieldValue('date_of_workshop', '');
          }
        };
        useEffect(() => {
          if (touched.date_of_workshop) {
            handleDateChange(values.date_of_workshop);
          }
        }, [touched.date_of_workshop]);
        return (
          <form onSubmit={handleSubmit} className={styles.loginFlow__form}>
            {submitting ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className={styles.fields}>
                  <div>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      InputLabelProps={{ required: true }}
                      error={touched.contact_name && Boolean(errors.contact_name)}
                      fullWidth
                      helperText={touched.contact_name && errors.contact_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactName' })}
                      name="contact_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      InputLabelProps={{ required: true }}
                      error={touched.contact_phone_number && Boolean(errors.contact_phone_number)}
                      fullWidth
                      helperText={touched.contact_phone_number && errors.contact_phone_number}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' })}
                      name="contact_phone_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_phone_number}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' }),
                      }}
                      InputProps={{
                        inputComponent: PhoneNumberInput,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      error={touched.contact_email && Boolean(errors.contact_email)}
                      fullWidth
                      helperText={touched.contact_email && errors.contact_email}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' })}
                      name="contact_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_email}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
                      }}
                    />
                  </div>
                  <Box>
                    <DatePicker
                      onError={(newError) => {
                        if (['minDate', 'maxDate'].indexOf(newError) > -1) {
                          setDateError(intl.formatMessage({ id: 'jobSearchActivity.form.dateOutOfRange' }));
                        } else {
                          setDateError(newError);
                        }
                      }}
                      disabled={submitting || (editting && !editMode)}
                      label={`${intl.formatMessage({ id: 'jobSearchActivity.resea.dateOfResea' })} *`}
                      minDate={new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10))}
                      maxDate={new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10))}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          helperText:
                            touched.date_of_workshop && errors.date_of_workshop ? errors.date_of_workshop : dateError,
                          error: dateError || (touched.date_of_workshop && Boolean(errors.date_of_workshop)),
                        },
                      }}
                      onChange={(newValue) => {
                        setFieldValue('date_of_workshop', newValue);
                      }}
                      value={values.date_of_workshop}
                    />
                  </Box>
                </div>
                <div className={styles.boxes}>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.uploadDocuments' })}
                      </span>
                    </Box>
                    <Field
                      name="upload_documents"
                      disabled={submitting || (editting && !editMode)}
                      component={BrowseDocuments}
                      label="jobSearchActivity.resea.documentHint"
                    />
                    <div className={styles.downloadLinks}>
                      {documentsLinks.map((doc) =>
                        doc.downloadUrl ? (
                          <a href={doc.downloadUrl} key={doc.downloadUrl}>
                            {doc.filename}
                          </a>
                        ) : (
                          <span key={JSON.stringify(doc)}>...</span>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      </span>
                    </Box>
                    <TextField
                      id="filled-textarea"
                      placeholder={intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      multiline
                      rows={5}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 12, height: '112px' } }}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="notes"
                      disabled={submitting || (editting && !editMode)}
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={4}>
              <div />
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    type="button"
                    disabled={submitting}
                    size="large"
                    onClick={() => setOpen(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })
                    )}
                  </Button>
                </Box>
                {editting && !editMode ? (
                  <Button
                    color="secondary"
                    disabled={submitting}
                    size="large"
                    variant="contained"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent('WEEK_VIEWACTIVITY_EDIT');
                      setEditMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: 'layout.header.edit' })}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    disabled={submitting || dateError}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.save' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

Resea.propTypes = {
  addActivityItem: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  setOpen: PropTypes.func,
  selectedActivity: PropTypes.object,
  editting: PropTypes.bool,
  week: PropTypes.object,
};

export default Resea;
