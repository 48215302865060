import { useEffect } from 'react';
import { TextField } from '@mui/material';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import CheckIcon from '@mui/icons-material/Check';

import ExperienceWalletCustomAutocomplete from './ExperienceWalletCustomAutocomplete';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setCareers } from 'app/slices/careersSlice';

/* API */
import { useCareers } from 'shared/hooks';

export default function ExperienceWalletFieldsAutocomplete({
  isDisabled,
  value,
  onChange,
  error,
  helperText,
  open,
  setOpen,
  id,
}) {
  const intl = useIntl();
  const careersQuery = useCareers();
  const dispatch = useDispatch();

  const { results } = useSelector((state) => state.careers);

  useEffect(() => {
    if (careersQuery.isSuccess) {
      dispatch(setCareers(careersQuery.data));
    }
  }, [careersQuery.isSuccess, careersQuery.data, careersQuery.status]);

  return (
    <ExperienceWalletCustomAutocomplete
      disableClearable
      options={results}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => {
        return option.soc === value?.soc;
      }}
      disabled={isDisabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-root:not(.Mui-disabled)': {
          backgroundColor: 'white',
        },
        '& .MuiInputBase-root': {
          paddingTop: '10px!important',
          paddingBottom: '2px!important',
        },
        '& .MuiFilledInput-input': {
          fontSize: 12,
        },
      }}
      renderOption={(props, option, { selected }) => (
        <div {...props} style={{ display: 'flex', alignItems: 'center', margin: '2px 0', position: 'relative' }}>
          {selected ? <CheckIcon fontSize="small" style={{ position: 'absolute', left: 11 }} /> : null}
          <span style={{ paddingLeft: 20 }}>{option.title}</span>
        </div>
      )}
      renderTags={(value, getTagProps) => (
        <div style={{ width: '100%' }}>
          {value.map((option, index) => {
            const { className, ...rest } = getTagProps({ index }); //eslint-disable-line
            return (
              <Chip
                key={option.soc}
                size="small"
                sx={{ mr: 1, mt: 1 }}
                color="primary"
                label={option.title}
                {...rest}
              />
            );
          })}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          placeholder={intl.formatMessage({ id: 'experience.form.fieldsPlaceholder' })}
          inputProps={{
            ...params.inputProps,
            'aria-label': 'fields',
            id: id ?? 'field-match-dropdown',
          }}
          helperText={helperText}
          sx={{
            '& input::placeholder': {
              color: 'rgba(0,0,0,0.87)',
              opacity: 1,
            },
          }}
          error={error}
        />
      )}
    />
  );
}

ExperienceWalletFieldsAutocomplete.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
