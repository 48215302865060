import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const ScrollToTopButton = () => {
  const intl = useIntl();

  const scrollTop = (e) => {
    e.preventDefault();

    let toTopEvent = 'FOOTER_TOP';
    const activePath = location.hash.split('/').reverse()[0];

    if (activePath === 'careers') {
      toTopEvent = 'CAREERS_TOP';
    }
    if (activePath === 'jobs') {
      toTopEvent = 'JOBS_TOP';
    }
    if (activePath === 'training') {
      toTopEvent = 'TRAINING_TOP';
    }
    if (activePath === 'experience') {
      toTopEvent = 'EXPERIENCE_TOP';
    }

    trackEvent(toTopEvent);
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <span onClick={scrollTop} className={styles.backToTop} style={{ color: 'white' }}>
      {intl.formatMessage({ id: 'recommendations.scrollTop' })}
    </span>
  );
};

export default ScrollToTopButton;

export const ScrollToTopOnPathChange = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});
