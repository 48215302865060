import { useState } from 'react';
import { Box, Chip } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography, Collapse, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExperienceWalletDisplayItem from './ExperienceWalletDiplayItem';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

/* i18n */
import { useIntl } from 'react-intl';
import ExperienceWalletForm from './ExperienceWalletForm';

/* Icons */
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PlaylistAddCheckCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

export const typeIconDict = {
  apprenticeship: PeopleAltOutlinedIcon,
  assessment: PlaylistAddCheckCircleOutlinedIcon,
  badge: VerifiedOutlinedIcon,
  course: ClassOutlinedIcon,
  certification: CardMembershipOutlinedIcon,
  degree: SchoolOutlinedIcon,
  employment: MeetingRoomOutlinedIcon,
  internship: LocalLibraryOutlinedIcon,
  license: BadgeOutlinedIcon,
};

export const typeDict = {
  apprenticeship: 'experience.form.apprenticeshipType',
  assessment: 'experience.form.assessmentType',
  badge: 'experience.form.badgeType',
  course: 'experience.form.courseType',
  degree: 'experience.form.educationDegreeType',
  license: 'experience.form.licenseType',
  certification: 'experience.form.certificateType',
  internship: 'experience.form.internshipType',
  employment: 'experience.form.employmentType',
};

export default function ExperienceWalletDrawer({ experience, experiences, currentIndex, replaceItem, deleteItem }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const [currentContent, setCurrentContent] = useState('display');

  const RowIcon = typeIconDict[experience.type];

  return (
    <Box sx={{ mb: 1 }}>
      <Box
        sx={{
          boxSizing: 'border-box',
          borderRadius: 2,
          width: '100%',
          border: (theme) => `solid 1px ${theme.palette.grey[500]}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          px: 2,
          pt: 1,
          pb: experience.wallet ? 1 : 1,
          position: 'relative',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <RowIcon sx={{ color: 'secondary.main', fontSize: 24, marginTop: '15px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textOverflow: 'ellipsis',
            whiteSpace: expanded ? null : 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              ml: 1,
              mr: 2,
              fontSize: 16,
              textOverflow: expanded ? 'initial' : 'ellipsis',
              whiteSpace: expanded ? 'initial' : 'nowrap',
              overflow: 'hidden',
              position: 'relative',
              top: 6,
            }}
          >
            {intl.formatMessage({ id: typeDict[experience.type] })}{' '}
            {experience.wallet ? (
              <AccountBalanceWalletIcon sx={{ fontSize: 16, position: 'relative', top: 3, color: 'primary.dark' }} />
            ) : null}{' '}
          </Typography>
          {experience.wallet ? (
            <Typography sx={{ ml: 1, color: 'primary.main' }} variant="caption">
              {intl.formatMessage({ id: 'experience.fromWallet' })}
            </Typography>
          ) : null}
          <Typography
            variant="h3"
            component="h2"
            sx={{
              ml: 1,
              mr: 2,
              fontSize: 16,
              transition: 'all ease 0.5s',
              textOverflow: expanded ? 'initial' : 'ellipsis',
              whiteSpace: expanded ? 'initial' : 'nowrap',
              overflow: 'hidden',
            }}
          >
            {experience.description}
          </Typography>
        </Box>

        <Box
          sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', paddingTop: '6px' }}
        >
          <Chip
            sx={{ marginTop: '5px' }}
            label={`${experience.skills?.length ?? '0'} ${intl.formatMessage({
              id: experience.skills?.length === 1 ? 'experience.drawer.skillSingular' : 'experience.drawer.skillPlural',
            })}`}
          />
          <IconButton
            color="secondary"
            style={{ padding: 8 }}
            title={intl.formatMessage({ id: 'accessibility.expandButton' })}
            size="large"
          >
            {expanded ? <KeyboardArrowUpIcon sx={{ fontSize: 26 }} /> : <KeyboardArrowDownIcon sx={{ fontSize: 26 }} />}
          </IconButton>
        </Box>
      </Box>
      <Collapse
        in={expanded}
        sx={{
          border: (theme) => `solid 1px ${theme.palette.grey[500]}`,
          borderRadius: 2,
          px: 2,
        }}
      >
        {currentContent === 'display' ? (
          <ExperienceWalletDisplayItem
            experience={experience}
            setCurrentContent={setCurrentContent}
            deleteItem={deleteItem}
            length={experiences.length}
            index={currentIndex}
          />
        ) : (
          <ExperienceWalletForm
            experience={experience}
            setCurrentContent={setCurrentContent}
            currentIndex={currentIndex}
            replaceItem={replaceItem}
            experiences={experiences}
          />
        )}
      </Collapse>
    </Box>
  );
}
ExperienceWalletDrawer.propTypes = {
  currentIndex: PropTypes.number,
  replaceItem: PropTypes.func,
  experiences: PropTypes.array,
  deleteItem: PropTypes.func,
  experience: PropTypes.shape({
    type: PropTypes.string,
    fields: PropTypes.array,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    description: PropTypes.string,
    entity: PropTypes.string,
    wallet: PropTypes.bool,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slice: PropTypes.func,
      })
    ),
  }),
};
