import { useEffect, useMemo } from 'react';
import { TextField, Chip, Autocomplete, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { useSkills } from 'shared/hooks';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setSkills } from 'app/slices/skillsSlice';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SkillsMultiselectFilter({ isDisabled, value, onChange, enabledSkills, id }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state.skills);
  const visibleSkills = useMemo(
    () =>
      enabledSkills
        .map((eSk) => {
          const skIndex = binarySearch(skills, eSk);
          if (skIndex > -1) {
            return skills[skIndex];
          }
          return false;
        })
        .filter((sk) => sk !== false),
    [skills, enabledSkills]
  );
  const skillsQuery = useSkills();

  useEffect(() => {
    if (skillsQuery.isSuccess) {
      dispatch(setSkills(skillsQuery.data));
    }
  }, [skillsQuery.isSuccess, skillsQuery.data]);

  const renderTags = (value, getTagProps) => {
    const limit = 1; // Set the maximum number of tags to display

    return (
      <div>
        {value.slice(0, limit).map((option, index) => {
          const { className, ...rest } = getTagProps({ index }); //eslint-disable-line

          return (
            <Chip
              key={option}
              size="small"
              label={skills.find((opt) => opt.id === option)?.name}
              {...rest}
              color="primary"
              sx={{ maxWidth: 180 }}
            />
          );
        })}
        <span style={{ fontSize: 15, position: 'relative', top: 1, color: '#424242' }}>
          {value.length > limit ? `+${value.length - limit}` : ''}
        </span>
      </div>
    );
  };

  return (
    <Autocomplete
      multiple
      disabled={isDisabled}
      disableCloseOnSelect={true}
      id={id ?? 'skills-multiselect-filter'}
      options={visibleSkills}
      renderTags={renderTags}
      value={value}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value}
      //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
      renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: 'careers.filters.skills' })} />}
      onChange={(_, value) => onChange(value.map((val) => (typeof val === 'string' ? val : val.id)))}
      classes={{
        paper: 'autocomplete-paper-border',
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          paddingTop: '4px!important',
        },
        '& .MuiAutocomplete-input': {
          fontSize: 12,
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: '0 !important',
        },
      }}
    />
  );
}

SkillsMultiselectFilter.propTypes = {
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  enabledSkills: PropTypes.arrayOf(PropTypes.string),
};

function binarySearch(arr, targetString) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const currentElement = arr[mid];

    if (currentElement.id === targetString) {
      return mid;
    } else if (currentElement.id < targetString) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // Element not found
  return -1;
}
