import { createSlice } from '@reduxjs/toolkit';

export const careersSlice = createSlice({
  name: 'careers',
  initialState: {
    results: [],
    pageSize: 10,
    page: 0,
    goal: '',
    showRecommendedCareers: true,
    tagsActive: [],
    salaryRangesFilter: [],
    careerOutlookFilter: false,
    skillsFilter: [],
    requirementsFilter: [],
    filterOptions: {
      skills: [],
    },
  },
  reducers: {
    setCareers: (state, action) => {
      state.results = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setGoal: (state, action) => {
      state.goal = action.payload;
    },
    setSkillsFilter: (state, action) => {
      state.skillsFilter = action.payload;
    },
    setTagsActive: (state, action) => {
      state.tagsActive = action.payload;
    },
    setSalaryRangesFilter: (state, action) => {
      state.salaryRangesFilter = action.payload;
    },
    setCareerOutlookFilter: (state, action) => {
      state.careerOutlookFilter = action.payload;
    },
    setRequirementsFilter: (state, action) => {
      state.requirementsFilter = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setShowRecommendedCareers: (state, action) => {
      state.showRecommendedCareers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCareers,
  setPage,
  setGoal,
  setSkillsFilter,
  setTagsActive,
  setSalaryRangesFilter,
  setCareerOutlookFilter,
  setRequirementsFilter,
  setFilterOptions,
  setShowRecommendedCareers,
} = careersSlice.actions;

export default careersSlice.reducer;
