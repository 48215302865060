import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { apiRequest } from 'lib/API';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';

/**
 * i18n
 */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

import styles from 'styles/apply-job-form.module.scss';
import onbStyles from 'styles/onboarding.module.scss';

import BrowseDocuments from 'components/BrowseDocuments';

function AttendJobSeminar({
  addActivityItem,
  setOpen,
  updateActivityItem,
  setGeneralActivity,
  selectedActivity,
  editting = false,
  week,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [refreshConter, setRefreshConter] = useState(0);
  const [documentsLinks, setDocumentsLinks] = useState([]);
  const [dateError, setDateError] = useState('');
  const intl = useIntl();

  useEffect(() => {
    setRefreshConter(refreshConter + 1);
    if (selectedActivity.upload_documents && selectedActivity.upload_documents.length > 0) {
      setDocumentsLinks([...selectedActivity.upload_documents]);
    }
  }, [selectedActivity, editting]);

  useEffect(() => {
    const documentsWithNoLinks = documentsLinks.filter((d) => !d.downloadUrl);
    if (documentsWithNoLinks.length > 0) {
      documentsLinks.forEach((doc, idx) => {
        if (doc.downloadUrl) {
          return;
        }
        apiRequest(
          'get',
          `/users/me/worksearch/${encodeURIComponent(selectedActivity.worksearch_id)}?file_uuid=${encodeURIComponent(
            doc.file_uuid
          )}`
        ).then((res) => {
          setDocumentsLinks([
            ...documentsLinks.slice(0, idx),
            { ...doc, downloadUrl: res.activity?.download_url },
            ...documentsLinks.slice(idx + 1),
          ]);
        });
      });
    }
  }, [JSON.stringify(documentsLinks)]);

  const emailRegExp = /^\S+@\S+\.\w\w+$/;

  const validationSchema = yup.object({
    job_fair: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    job_fair_website: yup.string(intl.formatMessage({ id: 'jobSearchActivity.fair.urlRequired' })).matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, //eslint-disable-line
      intl.formatMessage({ id: 'jobSearchActivity.fair.urlRequired' })
    ),
    contact_email: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(emailRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    date_of_job_fair: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    time_of_job_fair: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    location: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    result_of_job_fair: yup.array().min(1, intl.formatMessage({ id: 'forms.onboarding.minSelected' }, { length: 1 })),
  });

  const toggleCheckbox = (currentValues, key) => {
    const valuesSet = new Set(currentValues);
    if (valuesSet.has(key)) {
      valuesSet.delete(key);
    } else {
      valuesSet.add(key);
    }
    return [...valuesSet];
  };

  const defaultValues = {
    job_fair: '',
    job_fair_website: '',
    contact_email: '',
    date_of_job_fair: null,
    time_of_job_fair: '',
    location: '',
    result_of_job_fair: '',
    result_of_job_fair_other: '',
    notes: '',
    upload_documents: [],
    draft: false,
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={
        selectedActivity
          ? {
              ...selectedActivity,
              upload_documents: [],
              time_of_job_fair: new Date(selectedActivity.time_of_job_fair),
              date_of_job_fair: new Date(
                selectedActivity.date_of_job_fair.slice(0, 4),
                selectedActivity.date_of_job_fair.slice(5, 7) - 1,
                selectedActivity.date_of_job_fair.slice(8, 10)
              ),
            }
          : defaultValues
      }
      onSubmit={async ({ result_of_job_fair_other, result_of_job_fair, ...values }) => {
        setSubmitting(true);
        let year = values.date_of_job_fair.getFullYear();
        let month = values.date_of_job_fair.getMonth() + 1;
        let day = values.date_of_job_fair.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        const resultVal = result_of_job_fair.includes('other')
          ? result_of_job_fair.filter((v) => v != 'other').concat(result_of_job_fair_other)
          : result_of_job_fair;

        const vals4submit = {
          ...values,
          result_of_job_fair: resultVal,
          activity_type: 'attend_job_fair',
          activity_date: `${year}-${month}-${day}`,
          date_of_job_fair: `${year}-${month}-${day}`,
        };
        vals4submit.upload_documents = values.upload_documents.map((doc) => ({
          filename: doc.name,
          upload_contents: doc.contents,
        }));
        if (editting) {
          trackEvent('WEEK_VIEWACTIVITY_SAVE');
        } else {
          trackEvent('WEEK_SAVEACTIVITY_JOBFAIR');
        }
        apiRequest(editting ? 'put' : 'post', '/users/me/worksearch/', { data: { activity: vals4submit } })
          .then(({ activity, status }) => {
            if (editting) {
              updateActivityItem(activity);
            } else {
              addActivityItem(activity);
            }
            setGeneralActivity(status);
            setOpen(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }
        const handleDateChange = (date) => {
          const minDate = new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10));
          const maxDate = new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10));
          if (isValidDate(date)) {
            setFieldValue('date_of_job_fair', date);
            if (date < minDate || date > maxDate) {
              setDateError('The selected date is outside the valid range');
            } else {
              setDateError('');
            }
          } else {
            let error = intl.formatMessage({ id: 'jobSearchActivity.validation.invalidDateFormat' });
            if (Object.is(date, null)) {
              error = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            setDateError(error);
            setFieldValue('date_of_job_fair', '');
          }
        };
        useEffect(() => {
          if (touched.date_of_job_fair) {
            handleDateChange(values.date_of_job_fair);
          }
        }, [touched.date_of_job_fair]);
        return (
          <form onSubmit={handleSubmit} className={styles.loginFlow__form}>
            {submitting ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className={styles.fields}>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.job_fair && Boolean(errors.job_fair)}
                      fullWidth
                      helperText={touched.job_fair && errors.job_fair}
                      label={intl.formatMessage({ id: 'jobSearchActivity.fair.jobFair' })}
                      margin="normal"
                      name="job_fair"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.job_fair}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.fair.jobFair' }),
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      error={touched.job_fair_website && Boolean(errors.job_fair_website)}
                      fullWidth
                      helperText={touched.job_fair_website && errors.job_fair_website}
                      label={intl.formatMessage({ id: 'jobSearchActivity.fair.jobFairWebsite' })}
                      margin="normal"
                      name="job_fair_website"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.job_fair_website}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.fair.jobFairWebsite' }),
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      error={touched.contact_email && Boolean(errors.contact_email)}
                      fullWidth
                      helperText={touched.contact_email && errors.contact_email}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' })}
                      name="contact_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_email}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
                      }}
                    />
                  </div>
                  <Box>
                    <DatePicker
                      onError={(newError) => {
                        if (['minDate', 'maxDate'].indexOf(newError) > -1) {
                          setDateError(intl.formatMessage({ id: 'jobSearchActivity.form.dateOutOfRange' }));
                        } else {
                          setDateError(newError);
                        }
                      }}
                      disabled={submitting || (editting && !editMode)}
                      label={`${intl.formatMessage({ id: 'jobSearchActivity.fair.dateOfJobFair' })} *`}
                      minDate={new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10))}
                      maxDate={new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10))}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          helperText:
                            touched.date_of_job_fair && errors.date_of_job_fair ? errors.date_of_job_fair : dateError,
                          error: dateError || (touched.date_of_job_fair && Boolean(errors.date_of_job_fair)),
                        },
                      }}
                      onChange={(newValue) => {
                        setFieldValue('date_of_job_fair', newValue);
                      }}
                      value={values.date_of_job_fair}
                    />
                  </Box>
                  <Box>
                    <TimePicker
                      disabled={submitting || (editting && !editMode)}
                      label={`${intl.formatMessage({ id: 'jobSearchActivity.fair.timeOfJobFair' })} *`}
                      minDate={new Date(week.from.slice(0, 4), week.from.slice(5, 7) - 1, week.from.slice(8, 10))}
                      maxDate={new Date(week.to.slice(0, 4), week.to.slice(5, 7) - 1, week.to.slice(8, 10))}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          helperText: touched.time_of_job_fair && errors.time_of_job_fair,
                          error: touched.time_of_job_fair && Boolean(errors.time_of_job_fair),
                        },
                      }}
                      onChange={(newValue) => {
                        setFieldValue('time_of_job_fair', newValue);
                      }}
                      value={values.time_of_job_fair}
                    />
                  </Box>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.location && Boolean(errors.location)}
                      fullWidth
                      helperText={touched.location && errors.location}
                      label={intl.formatMessage({ id: 'jobSearchActivity.fair.locationOfJobFair' })}
                      name="location"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.location}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.fair.locationOfJobFair' }),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.checksAndRadios}>
                  <div className={styles.checks}>
                    <span
                      className={cn({
                        [styles.fieldLabel]: true,
                        [styles.fieldLabel__errored]: touched.result_of_job_fair && errors.result_of_job_fair,
                      })}
                    >
                      {intl.formatMessage({ id: 'jobSearchActivity.fair.ResultOfJobFair' })}*
                    </span>
                    <span className={cn(styles.fieldDescription, styles.labelPadding)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.allThatApply' })}
                    </span>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption1' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.result_of_job_fair.includes('resumes_submitted')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'result_of_job_fair',
                                  value: toggleCheckbox(values.result_of_job_fair, 'resumes_submitted'),
                                },
                              })
                            }
                            name="result_of_job_fair"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption2' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.result_of_job_fair.includes('interviews_scheduled')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'result_of_job_fair',
                                  value: toggleCheckbox(values.result_of_job_fair, 'interviews_scheduled'),
                                },
                              })
                            }
                            name="result_of_job_fair"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption3' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.result_of_job_fair.includes('performed_interview')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'result_of_job_fair',
                                  value: toggleCheckbox(values.result_of_job_fair, 'performed_interview'),
                                },
                              })
                            }
                            name="result_of_job_fair"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption4' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.result_of_job_fair.includes('other')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'result_of_job_fair',
                                  value: toggleCheckbox(values.result_of_job_fair, 'other'),
                                },
                              })
                            }
                            name="result_of_job_fair"
                          />
                        }
                      />
                    </FormControl>
                    {values.result_of_job_fair.includes('other') ? (
                      <Box pt={1}>
                        <TextField
                          InputLabelProps={{ required: true }}
                          disabled={submitting || (editting && !editMode)}
                          error={touched.result_of_job_fair_other && Boolean(errors.result_of_job_fair_other)}
                          fullWidth
                          helperText={touched.result_of_job_fair_other && errors.result_of_job_fair_other}
                          label={intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption4' })}
                          name="result_of_job_fair_other"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          classes={{ root: styles.textFieldRoot }}
                          type="text"
                          value={values.result_of_job_fair_other}
                          variant="filled"
                          inputProps={{
                            title: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption4' }),
                          }}
                        />
                      </Box>
                    ) : null}
                    <div style={{ position: 'relative', top: '-27px', left: '-26px' }}>
                      {touched.result_of_job_fair && errors.result_of_job_fair && (
                        <FormHelperText classes={{ root: onbStyles.tosHelperTextRoot }}>
                          {errors.result_of_job_fair}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.boxes}>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.uploadDocuments' })}
                      </span>
                    </Box>
                    <Field
                      name="upload_documents"
                      disabled={submitting || (editting && !editMode)}
                      component={BrowseDocuments}
                      label="jobSearchActivity.fair.documentHint"
                    />
                    <div className={styles.downloadLinks}>
                      {documentsLinks.map((doc) =>
                        doc.downloadUrl ? (
                          <a key={doc.downloadUrl} href={doc.downloadUrl}>
                            {doc.filename}
                          </a>
                        ) : (
                          <span key={JSON.stringify(doc)}>...</span>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      </span>
                    </Box>
                    <TextField
                      id="filled-textarea"
                      disabled={submitting || (editting && !editMode)}
                      placeholder={intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      multiline
                      rows={5}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 12, height: '112px' } }}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="notes"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={4}>
              <div />
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    type="button"
                    disabled={submitting}
                    size="large"
                    onClick={() => setOpen(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })
                    )}
                  </Button>
                </Box>
                {editting && !editMode ? (
                  <Button
                    color="secondary"
                    disabled={submitting}
                    size="large"
                    variant="contained"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent('WEEK_VIEWACTIVITY_EDIT');
                      setEditMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: 'layout.header.edit' })}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    disabled={submitting || dateError}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.save' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

AttendJobSeminar.propTypes = {
  addActivityItem: PropTypes.func,
  setOpen: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  selectedActivity: PropTypes.any,
  editting: PropTypes.bool,
  week: PropTypes.object,
};

export default AttendJobSeminar;
