import { createSlice } from '@reduxjs/toolkit';

export const workSearchSlice = createSlice({
  name: 'worksearch',
  initialState: {
    activities: [],
    guid: '',
    status: {
      overall_activities_completed: 0,
      overall_activities_required: 0,
      weekly_activities_completed: 0,
      weekly_activities_required: 0,
      overall_activity_start_date: '',
      overall_activity_end_date: '',
      weekly_activity_start_date: '',
      weekly_activity_end_date: '',
    },
    loaded: false,
    openOnEditMode: false,
  },
  reducers: {
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    setGuid: (state, action) => {
      state.guid = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setOpenOnEditMode: (state, action) => {
      state.openOnEditMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActivities, setGuid, setStatus, setLoaded, setOpenOnEditMode } = workSearchSlice.actions;

export default workSearchSlice.reducer;
