import { useEffect, useState } from 'react';
import { Box, Chip, Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Tooltip,
  MenuItem,
  Menu,
  ListItemIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  darken,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { typeIconDict, typeDict } from './ExperienceWalletDrawer';

/* i18n */
import { useIntl } from 'react-intl';

const Title = ({ title, showArrow, sortDirection }) => (
  <>
    <Typography sx={{ fontWeight: 700, color: 'white', pt: 2, pb: 2 }}>{title}</Typography>
    {showArrow ? (
      sortDirection === 'asc' ? (
        <KeyboardArrowUpIcon sx={{ color: 'white' }} />
      ) : (
        <KeyboardArrowDownIcon sx={{ color: 'white' }} />
      )
    ) : null}
  </>
);
Title.propTypes = {
  title: PropTypes.string,
  showArrow: PropTypes.bool,
  sortDirection: PropTypes.string,
};

function getSortDirection(currentSortBy, newSortBy, currentDirection) {
  if (currentSortBy !== newSortBy) {
    return 'asc';
  }
  if (currentDirection === 'asc') {
    return 'desc';
  }
  return 'asc';
}

export const getVisibleItems = (data, currentPage, itemsPerPage) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  return data.slice(startIndex, endIndex);
};

export default function ExperienceWalletDesktopTable({
  experience,
  deleteItem,
  setItemToEdit,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
}) {
  const intl = useIntl();
  const commontHeaderSxProps = {
    backgroundColor: '#344752',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(experience.length / itemsPerPage));

  useEffect(() => {
    setTotalPages(Math.ceil(experience.length / itemsPerPage));
  }, [experience.length]);

  const visibleItems = getVisibleItems(experience, totalPages > 1 ? currentPage : 1, itemsPerPage);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '268px 2fr 3fr 2fr 2fr 50px',
          boxShadow: '0px 3px 12px rgba(0,0,0,0.1)',
        }}
      >
        <Box
          sx={{ pl: 3, ...commontHeaderSxProps }}
          onClick={() => {
            setSortDirection(getSortDirection(sortBy, 'experience', sortDirection));
            setSortBy('experience');
          }}
        >
          <Title
            title={intl.formatMessage({ id: 'experience.table.experience' })}
            showArrow={sortBy === 'experience'}
            sortDirection={sortDirection}
          />
        </Box>
        <Box
          sx={{ ...commontHeaderSxProps }}
          onClick={() => {
            setSortDirection(getSortDirection(sortBy, 'description', sortDirection));
            setSortBy('description');
          }}
        >
          <Title
            title={intl.formatMessage({ id: 'experience.table.description' })}
            showArrow={sortBy === 'description'}
            sortDirection={sortDirection}
          />
        </Box>
        <Box
          sx={{ ...commontHeaderSxProps }}
          onClick={() => {
            setSortDirection(getSortDirection(sortBy, 'numberSkills', sortDirection));
            setSortBy('numberSkills');
          }}
        >
          <Title
            title={intl.formatMessage({ id: 'experience.table.assocSkills' })}
            showArrow={sortBy === 'numberSkills'}
            sortDirection={sortDirection}
          />
        </Box>
        <Box
          sx={{ ...commontHeaderSxProps }}
          onClick={() => {
            setSortDirection(getSortDirection(sortBy, 'default', sortDirection));
            setSortBy('default');
          }}
        >
          <Title
            title={intl.formatMessage({ id: 'experience.form.duration' })}
            showArrow={sortBy === 'default'}
            sortDirection={sortDirection}
          />
        </Box>
        <Box
          sx={{ ...commontHeaderSxProps, gridColumn: 'span 2' }}
          onClick={() => {
            setSortDirection(getSortDirection(sortBy, 'provider', sortDirection));
            setSortBy('provider');
          }}
        >
          <Title
            title={intl.formatMessage({ id: 'experience.form.provider' })}
            showArrow={sortBy === 'provider'}
            sortDirection={sortDirection}
          />
        </Box>
        {visibleItems.map((item, index) => (
          <ExperienceWalletDesktopRow
            item={item}
            key={item.id}
            length={experience.length}
            deleteItem={deleteItem}
            grey={index % 2 === 0}
            index={index + (currentPage - 1) * itemsPerPage}
            setItemToEdit={setItemToEdit}
          />
        ))}
      </Box>
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, value) => setCurrentPage(value)}
            showFirstButton
            showLastButton
            shape="rounded"
          />
        )}
      </Box>
    </>
  );
}
ExperienceWalletDesktopTable.propTypes = {
  currentIndex: PropTypes.number,
  replaceItem: PropTypes.func,
  deleteItem: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortBy: PropTypes.func,
  setSortDirection: PropTypes.func,
  setItemToEdit: PropTypes.func,
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      field: PropTypes.object,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      description: PropTypes.string,
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          slice: PropTypes.func,
        })
      ),
    })
  ),
};
function ExperienceWalletDesktopRow({ item, index, deleteItem, length, grey, setItemToEdit }) {
  const RowIcon = typeIconDict[item.type];
  const intl = useIntl();
  const bgcolor = () => (grey ? '#e4e4e4' : null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [atLeast1Open, setAtLeast1Open] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ bgcolor, display: 'flex', flexDirection: 'column', justifyContent: 'center', pl: 3, py: 3 }}>
        <Box sx={{ bgcolor, display: 'flex', alignItems: 'center' }}>
          <RowIcon sx={{ color: 'secondary.dark', fontSize: 24, mr: 0.7 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ position: 'relative', top: item.wallet ? 3 : 0 }}>
              <strong>{typeDict[item.type] ? intl.formatMessage({ id: typeDict[item.type] }) : ''}</strong>{' '}
              {item.wallet ? (
                <AccountBalanceWalletIcon sx={{ fontSize: 16, position: 'relative', top: 4, color: 'primary.dark' }} />
              ) : null}
            </Typography>
            {item.wallet ? (
              <Typography sx={{ color: 'primary.main', position: 'relative', top: -2 }} variant="caption">
                {intl.formatMessage({ id: 'experience.fromWallet' })}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ pl: 3.5, pt: 0.3 }}>{item.field?.title}</Box>
      </Box>
      <Box sx={{ bgcolor, display: 'flex', alignItems: 'center', px: 1 }}>
        <Tooltip title={item.description?.length > 40 ? item.description : ''}>
          {item.description ? item.description.slice(0, 40) : ''}
          {item.description?.length > 40 ? '…' : ''}
        </Tooltip>
      </Box>
      <Box sx={{ bgcolor, display: 'flex', alignItems: 'center', pr: 1 }}>
        {item.skills &&
          item.skills?.length > 0 &&
          item.skills
            .slice(0, 2)
            .map((skill) => (
              <Chip
                label={skill.name}
                icon={skill.wallet ? <AccountBalanceWalletIcon /> : null}
                color={skill.wallet ? 'primary' : 'secondary'}
                key={skill.id}
                size="small"
                sx={{ '& .MuiChip-icon': { fontSize: 14 } }}
              />
            ))}
        {item.skills?.length > 2 ? (
          <Typography
            component="span"
            sx={{
              fontSize: 14,
              ml: 0.3,
              cursor: 'pointer',
              color: (theme) => darken(theme.palette.primary.dark, 0.2),
              position: 'relative',
            }}
            onClick={() => setItemToEdit(index)}
          >
            +{item.skills?.length - 2}
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ bgcolor, display: 'flex', alignItems: 'center' }}>
        {format(new Date(`${item.start_date}T00:00:00`), item.end_date ? 'MM/yyyy' : 'MM/dd/yyyy')}
        {item.end_date ? '-' : null}
        {item.end_date ? format(new Date(`${item.end_date}T00:00:00`), 'MM/yyyy') : null}
      </Box>
      <Box sx={{ bgcolor, display: 'flex', alignItems: 'center' }}>{item.employer_provider}</Box>
      <Box sx={{ bgcolor, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={intl.formatMessage({ id: 'experience.row.actions' })}>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'row-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              color="secondary.dark"
            >
              <MoreVertIcon sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="row-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => setItemToEdit(index)} id={`edit-experience-button-${index}`}>
            <ListItemIcon>
              <EditIcon fontSize="small" sx={{ color: 'secondary.dark' }} />
            </ListItemIcon>
            {intl.formatMessage({ id: 'experience.row.edit' })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (length !== 1) {
                deleteItem(index);
              } else {
                setAtLeast1Open(true);
              }
            }}
            id={`delete-experience-button-${index}`}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" sx={{ color: 'secondary.dark' }} />
            </ListItemIcon>
            {intl.formatMessage({ id: 'experience.row.delete' })}
          </MenuItem>
        </Menu>
      </Box>
      <Dialog open={atLeast1Open} onClose={() => setAtLeast1Open(false)}>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'experience.atLeast1' })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setAtLeast1Open(false)}>
            {intl.formatMessage({ id: 'experience.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
ExperienceWalletDesktopRow.propTypes = {
  deleteItem: PropTypes.func,
  setItemToEdit: PropTypes.func,
  length: PropTypes.number,
  grey: PropTypes.bool,
  index: PropTypes.number,
  item: PropTypes.shape({
    wallet: PropTypes.bool,
    id: PropTypes.string,
    type: PropTypes.string,
    field: PropTypes.object,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    description: PropTypes.string,
    employer_provider: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slice: PropTypes.func,
      })
    ),
  }),
};
