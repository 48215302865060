import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExperienceWalletDrawer from './ExperienceWalletDrawer';
import { Box, Pagination } from '@mui/material';
import { getVisibleItems } from './ExperienceWalletDesktopTable';

export default function MobilePaginatedDrawers({ experience, replaceItem, deleteItem }) {
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(experience.length / itemsPerPage));

  useEffect(() => {
    setTotalPages(Math.ceil(experience.length / itemsPerPage));
  }, [experience.length]);

  const visibleItems = getVisibleItems(experience, totalPages > 1 ? currentPage : 1, itemsPerPage);

  return (
    <>
      {visibleItems.map((exp, idx) => {
        return (
          <ExperienceWalletDrawer
            experience={exp}
            key={exp.id}
            currentIndex={idx + (currentPage - 1) * itemsPerPage}
            replaceItem={replaceItem}
            deleteItem={deleteItem}
            experiences={experience}
          />
        );
      })}
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, value) => setCurrentPage(value)}
            showFirstButton
            showLastButton
            size="small"
            shape="rounded"
          />
        )}
      </Box>
    </>
  );
}

MobilePaginatedDrawers.propTypes = {
  replaceItem: PropTypes.func,
  deleteItem: PropTypes.func,
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      field: PropTypes.object,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      description: PropTypes.string,
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          slice: PropTypes.func,
        })
      ),
    })
  ),
};
