import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { propOr } from 'ramda';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* API */
import { updateUserData, createUser, updateExperience } from 'shared/src/lib/API';
import { getSettings } from 'lib/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import {
  setNeedsContactInfo,
  setPreferredName,
  setContactUpdate,
  setOnboardingGuid,
  setUserSettings,
} from 'app/slices/appSlice';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import OnboardingSteps from 'components/OnboardingSteps';
import {
  TextField,
  Button,
  Box,
  FormControl,
  CircularProgress,
  Checkbox,
  FormHelperText,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DOBInput } from 'shared/components';
import SSNInput from 'components/inputs/FullSSNInput';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Utils */
import { preventSubmitOnEnter } from 'shared/utils';

export default function OnboardingContact() {
  const notRefetch = { retry: false, refetchOnWindowFocus: false };
  const idQuery = useQuery({ queryKey: ['/users/me'], ...notRefetch });

  if (idQuery.isLoading) {
    return (
      <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  return <ContactForm idQuery={idQuery} />;
}

function ContactForm({ idQuery }) {
  const { isSuccess: idSuccess, data } = idQuery;
  const [submitting, setSubmitting] = useState(false);
  const [ssnType, setSsnType] = useState('password');
  const [contactError, setContactError] = useState(false);

  const dispatch = useDispatch();
  const isSmall = useMediaQuery('(max-width:767px)');
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  const history = useHistory();
  const { contactUpdate } = useSelector((state) => state.app);
  const { hasSsn } = useSelector((state) => state.app);

  const lettersNumbers = /^[^<>]+$/; // eslint-disable-line no-useless-escape
  const emailRegExp = /^\S+@\S+\.\w\w+$/;

  const validationSchema = yup.object({
    first_name: yup
      .string('forms.onboarding.firstNameValidation')
      .min(2, 'forms.onboarding.firstNameMinLength')
      .max(50, 'forms.onboarding.firstNameMaxLength')
      .matches(lettersNumbers, 'Only letters, numbers, spaces and basic punction are allowed')
      .required('forms.onboarding.firstNameRequired'),
    last_name: yup
      .string('forms.onboarding.lastNameValidation')
      .min(2, 'forms.onboarding.lastNameMinLength')
      .max(50, 'forms.onboarding.lastNameMaxLength')
      .matches(lettersNumbers, 'Only letters, numbers, spaces and basic punction are allowed')
      .required('forms.onboarding.lastNameRequired'),
    email: yup
      .string('forms.onboarding.emailRequired')
      .matches(emailRegExp, 'forms.onboarding.emailValidation')
      .required('forms.onboarding.emailRequired'),
    dob: yup
      .date('forms.onboarding.dateOfBirthValidation')
      .typeError('forms.onboarding.dateOfBirthInvalid')
      .min('01/01/1900', 'forms.onboarding.dateOfBirthTooOld')
      .max(new Date(), 'forms.onboarding.dateOfBirthTooNew')
      .required('forms.onboarding.dateOfBirthRequired'),
    ...(!hasSsn && {
      ssnfull: yup
        .string('forms.onboarding.fullSocialSecurityNumber')
        .matches(/^[0-9]+$/, 'forms.onboarding.onlyDigits')
        .min(9, 'forms.onboarding.exactlyXDigits')
        .max(9, 'forms.onboarding.exactlyXDigits'),
    }),
    tos: yup.bool().oneOf([true], 'forms.onboarding.tosError'),
  });
  const { user: auth0_user } = useAuth0();

  const getInitialValue = (key) => {
    const auth0Dict = {
      first_name: 'given_name',
      last_name: 'family_name',
      email: 'email',
    };
    const uiProfileDict = {
      first_name: 'first_name',
      email: 'email',
      last_name: 'last_name',
    };
    let fromServer = propOr('', key, data);
    const fromAuth0 = key in auth0Dict ? propOr('', auth0Dict[key], auth0_user) : '';
    if (fromServer) {
      return fromServer;
    }

    const lsProfile = JSON.parse(localStorage.getItem('ui_profile')) || {};
    const fromLocalStorage = key in uiProfileDict ? propOr('', uiProfileDict[key], lsProfile) : '';
    if (fromLocalStorage) {
      return fromLocalStorage;
    }

    return fromAuth0;
  };
  const initialValues = {
    first_name: getInitialValue('first_name'),
    last_name: getInitialValue('last_name'),
    email: getInitialValue('email'),
    dob: getInitialValue('dob'),
    ...(!hasSsn && { ssnfull: getInitialValue('ssnfull') }),
    tos: contactUpdate ? true : false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema, // eslint-disable-next-line
    onSubmit: async ({ tos, ssnfull, ...values }) => {
      // eslint-disable-line
      trackEvent('ONBOARDING_CONTACT_NEXT');
      setContactError('');
      setSubmitting(true);
      const valuesToSubmit = {
        ...values,
        ...(ssnfull ? { ssnfull } : {}), // Sending ssnfull always per discussion in CSB-2322
        preferred_name: propOr('', 'first_name', values),
      };
      let data = {
        language: intl.locale,
      };

      let actionFunc = idSuccess ? updateUserData : createUser;

      actionFunc(valuesToSubmit)
        .then(function () {
          getSettings().then((settings) => {
            dispatch(setUserSettings(settings));
          });
          dispatch(setContactUpdate(false));
          dispatch(setNeedsContactInfo(false));
          dispatch(setPreferredName(values.first_name));
          setSubmitting(false);
          updateExperience(data).catch((error) => {
            console.log(error);
          });
          history.push(`${urlPrefix}/onboarding/experience`);
          trackEvent('ONBOARDING_CONTACT_FIRST_NAME');
          trackEvent('ONBOARDING_CONTACT_LAST_NAME');
          trackEvent('ONBOARDING_CONTACT_EMAIL');
          if (ssnfull) {
            trackEvent('ONBOARDING_CONTACT_SSN');
          }
          if (auth0_user.sub && auth0_user.sub.indexOf('Login-Gov') > 0) {
            trackEvent('LOGIN_SIGNUPLOGINGOV');
          }
          dispatch(setOnboardingGuid({ ...values }));
        })
        .catch(function () {
          setSubmitting(false);
        });
    },
  });

  return (
    <Box sx={{ boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.1)' }}>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom sx={{ fontSize: 24, mt: 1 }}>
            {intl.formatMessage({ id: 'onboarding.title' }, { step: 1, totalSteps: 5 })}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 222, marginLeft: 'auto', marginRight: 'auto', mt: 4, mb: 1 }}>
          <OnboardingSteps activeStep={1} />
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <div className={styles.loadingScreen__cardsContainer}>
        <form
          onSubmit={formik.handleSubmit}
          className={cn(styles.loginFlow__form, styles.signInFormNoStripe, onboardingStyles.formContainer)}
        >
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
              '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
            }}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            fullWidth
            helperText={
              formik.touched.first_name &&
              formik.errors.first_name &&
              intl.formatMessage({ id: formik.errors.first_name })
            }
            label={
              <>
                {intl.formatMessage({ id: 'forms.onboarding.firstName' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="first_name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.first_name}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.firstName' }),
            }}
            id="first-name-onboard-link"
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
              '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
            }}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            fullWidth
            helperText={
              formik.touched.last_name && formik.errors.last_name && intl.formatMessage({ id: formik.errors.last_name })
            }
            label={
              <>
                {intl.formatMessage({ id: 'forms.onboarding.lastName' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="last_name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.last_name}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.lastName' }),
            }}
            id="last-name-onboard-link"
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
              '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
            }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email && intl.formatMessage({ id: formik.errors.email })}
            label={
              <>
                {intl.formatMessage({ id: 'forms.signIn.username' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.email}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.signIn.username' }),
            }}
            id="email-onboard-link"
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            sx={{
              '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
              '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
            }}
            error={formik.touched.dob && Boolean(formik.errors.dob)}
            name="dob"
            type="text"
            variant="filled"
            fullWidth
            helperText={formik.touched.dob && formik.errors.dob && intl.formatMessage({ id: formik.errors.dob })}
            autoComplete="off"
            label={
              <>
                {intl.formatMessage({ id: 'forms.onboarding.dateOfBirth' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            value={formik.values.dob}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            InputProps={{
              inputComponent: DOBInput,
            }}
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.dateOfBirth' }),
            }}
          />
          {!hasSsn ? (
            <Box mb={2}>
              <h2 className={styles.uiClaimantsTitle}>
                {intl.formatMessage({ id: 'forms.onboarding.forUiClaimants' })}
              </h2>
              <Typography align="left" variant="caption">
                {intl.formatMessage({ id: 'forms.onboarding.contact.whySssn' })}
              </Typography>
            </Box>
          ) : null}
          {!hasSsn ? (
            <div style={{ position: 'relative' }}>
              <TextField
                FormHelperTextProps={{
                  className: styles.helperText,
                }}
                sx={{
                  '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                  '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                }}
                InputLabelProps={{ ...(formik.values.ssnfull && { shrink: formik.values.ssnfull }) }}
                error={formik.touched.ssnfull && Boolean(formik.errors.ssnfull)}
                name="ssnfull"
                type={ssnType}
                variant="filled"
                fullWidth
                helperText={
                  formik.touched.ssnfull &&
                  formik.errors.ssnfull &&
                  intl.formatMessage({ id: formik.errors.ssnfull }, { amount: 9 })
                }
                autoComplete="off"
                label={intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' })}
                value={formik.values.ssnfull}
                onChange={formik.handleChange}
                onKeyDown={preventSubmitOnEnter}
                setSsnType={setSsnType}
                InputProps={{
                  inputComponent: SSNInput,
                }}
                inputProps={{
                  title: intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' }),
                }}
                id="social-security-onboard-link"
              />
              <IconButton
                title={intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' })}
                style={{ position: 'absolute', right: '0', top: '0' }}
                onClick={() => {
                  setSsnType(ssnType === 'password' ? 'text' : 'password');
                }}
                size="large"
              >
                {ssnType === 'text' ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
          ) : null}

          {contactError.length > 0 && (
            <div className={styles.errorMessage__container}>
              <label className={styles.errorMessage}>{contactError}</label>
            </div>
          )}
          <div className={onboardingStyles.tosContainer}>
            <FormControl
              classes={{
                root: onboardingStyles.formControlRoot,
              }}
            >
              <div
                style={
                  isSmall
                    ? { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }
                    : { display: 'flex', flexDirection: 'row' }
                }
              >
                <div style={{ position: 'relative', right: '10px' }}>
                  <Checkbox
                    id="t-o-s-onboard-checkbox"
                    checked={formik.values.tos}
                    value={formik.values.tos}
                    onChange={(e) => formik.handleChange({ target: { name: 'tos', value: e.currentTarget.checked } })}
                    name="tos"
                    color="primary"
                  />
                </div>

                <div
                  style={
                    isSmall
                      ? { paddingTop: '5px', position: 'relative', right: '9px' }
                      : {
                          paddingTop: '8px',
                          display: 'flex',
                          alignContent: 'center',
                          position: 'relative',
                          right: '9px',
                        }
                  }
                >
                  <label
                    htmlFor="t-o-s-onboard-checkbox"
                    style={
                      isSmall ? { fontSize: '0.68rem' } : { fontSize: '0.8rem', marginRight: '5px', paddingTop: '2px' }
                    }
                  >
                    {intl.formatMessage({ id: 'forms.onboarding.tos' })}
                  </label>
                  <a
                    rel="noreferrer noopener"
                    style={isSmall ? { marginLeft: '2%' } : null}
                    className={onboardingStyles.tosLink}
                    href={`/#${urlPrefix}/terms-of-use`}
                    target="_blank"
                    onClick={() => trackEvent('ONBOARDING_CONTACT_TERMS')}
                  >
                    {intl.formatMessage({ id: 'layout.footer.privacy' }).toLocaleLowerCase()}.
                  </a>
                </div>
              </div>
              {formik.touched.tos && formik.errors.tos && (
                <FormHelperText classes={{ root: onboardingStyles.tosHelperTextRoot }}>
                  {intl.formatMessage({ id: formik.errors.tos })}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={styles.submitBtn__container}>
            <Button
              color="secondary"
              disabled={submitting}
              size="large"
              type="submit"
              variant="contained"
              id="next-onboard-step-1-button"
            >
              {submitting ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                </div>
              ) : (
                intl.formatMessage({ id: 'forms.onboarding.next' })
              )}
            </Button>
          </div>
        </form>
      </div>
    </Box>
  );
}

ContactForm.propTypes = {
  idQuery: PropTypes.shape({
    isSuccess: PropTypes.bool,
    data: PropTypes.object,
  }),
};
