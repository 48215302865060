import { TextField, Chip, Autocomplete, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiselectFilter({ label, isDisabled, value, onChange, options, ...rest }) {
  const renderTags = (value, getTagProps) => {
    const limit = 1; // Set the maximum number of tags to display
    return (
      <div>
        {value.slice(0, limit).map((option, index) => {
          const { className, ...rest } = getTagProps({ index }); //eslint-disable-line

          return (
            <Chip
              key={option}
              size="small"
              label={options.find((opt) => opt.value === option)?.label}
              {...rest}
              color="primary"
              sx={{ maxWidth: 180, px: 0 }}
            />
          );
        })}
        <span style={{ fontSize: 15, position: 'relative', top: 1, color: '#424242' }}>
          {value.length > limit ? `+${value.length - limit}` : ''}
        </span>
      </div>
    );
  };

  return (
    <Autocomplete
      multiple
      disabled={isDisabled}
      disableCloseOnSelect={true}
      options={options}
      renderTags={renderTags}
      value={value}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.value === value}
      //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(_, value) => onChange(value.map((val) => (typeof val === 'string' ? val : val.value)))}
      classes={{
        paper: 'autocomplete-paper-border',
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          paddingTop: '4px!important',
        },
        '& .MuiAutocomplete-input': {
          fontSize: 12,
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: '0 !important',
        },
      }}
      {...rest}
    />
  );
}

MultiselectFilter.propTypes = {
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
