import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Chip, IconButton, Button, CardContent, Typography, Box } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Unstable_Grid2';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { trainingsDurationTextFormatter } from 'shared/src/components/RecommendationsCard';

/* i18n */
import { useIntl } from 'react-intl';

function newTab(url) {
  window.open(url, '_blank');
}

export default function TrainingCard({
  item,
  onButtonClick,
  rating,
  onLikeClick,
  onDislikeClick,
  ratingsPage = false,
}) {
  const intl = useIntl();
  const LikeIcon =
    rating === 'liked' ? ThumbUpAltIcon : rating === 'disliked' && ratingsPage ? ReplyOutlinedIcon : ThumbUpOffAltIcon;

  // Hide recommendation menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyClick = async (e) => {
    if (item.link) {
      newTab(item.link);
    }
    onButtonClick(e);
  };

  return (
    <Grid xs={12} md={4}>
      <Card
        sx={{
          height: '100%',
          boxShadow: ratingsPage ? 'none' : '0px 3px 12px rgba(0,0,0,0.1)',
          border: ratingsPage ? 'solid 1px rgba(0, 0, 0, 0.12)' : 'none',
        }}
      >
        <CardHeader
          action={
            <>
              <Button
                startIcon={<LikeIcon sx={{ color: 'primary.dark' }} />}
                onClick={onLikeClick}
                sx={{ color: 'primary.dark', px: ratingsPage ? 2 : 1 }}
                id="save-training-button"
              >
                {intl.formatMessage({
                  id:
                    rating === 'liked'
                      ? ratingsPage
                        ? 'ratings.unsave'
                        : 'careers.saved'
                      : rating === 'disliked' && ratingsPage
                      ? 'ratings.unhide'
                      : 'careers.saveThis',
                })}
              </Button>
              {!ratingsPage && (
                <IconButton aria-label="settings" onClick={handleClick} sx={{ color: '#202124' }}>
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.2)',
                  },
                }}
                disableScrollLock
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onDislikeClick();
                  }}
                  id="hide-recommendation-training-link"
                >
                  {intl.formatMessage({ id: 'recommendations.hide' })}
                </MenuItem>
              </Menu>
            </>
          }
          sx={{ px: 2.5 }}
        />
        <CardContent sx={{ pt: 1.5, height: 'calc(100% - 64px)', boxSizing: 'border-box' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: { md: 1 },
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ pb: 1 }}>
                <Typography variant="h4" component="h2" sx={{ lineHeight: '24px', color: 'secondary.dark' }}>
                  {`title_${intl.locale}` in item && item[`title_${intl.locale}`].trim() !== ''
                    ? item[`title_${intl.locale}`]
                    : item.title}
                </Typography>
                <Box sx={{ color: 'secondary.main' }}>{capitalizeFirstLetter(item.training_provider)}</Box>
              </Box>
              <Chip
                label={`${intl.formatMessage({ id: 'careers.filter.careers' })}: ${item.soc_name}`}
                variant="outlined"
                title={item.soc_name}
                sx={{
                  mt: 0.6,
                  borderRadius: 2,
                  borderColor: 'secondary.light',
                  '& .MuiChip-label': { color: 'secondary.dark' },
                }}
              />

              <Box sx={{ mt: 2, minHeight: 49 }}>
                <Box>
                  <span style={{ fontWeight: 500 }}>
                    {item.duration ? (
                      <CalendarMonthOutlinedIcon sx={{ fontSize: 20, position: 'relative', top: 4 }} />
                    ) : null}
                  </span>{' '}
                  {item.duration && `duration_units_${intl.locale}` in item
                    ? `${item.duration} ${item[`duration_units_${intl.locale}`]}`
                    : trainingsDurationTextFormatter(intl, item.duration)}
                </Box>
                <Box>
                  <PlaceOutlinedIcon sx={{ fontSize: 20, position: 'relative', top: 4 }} /> {item.city}, {item.county}
                </Box>
              </Box>
            </Box>
            <Button
              color="primary"
              type="button"
              variant="contained"
              onClick={handleApplyClick}
              sx={{
                pl: 2,
                pr: 2,
                mt: { xs: 1.5 },
              }}
              aria-label={intl.formatMessage({ id: 'training.cta' })}
              id="visit-training-site-button"
            >
              <span>
                &nbsp;
                {intl.formatMessage({
                  id: 'training.cta',
                })}
                &nbsp;
                <OpenInNewIcon sx={{ fontSize: 16, position: 'relative', top: 3, marginLeft: 0.5 }} />
              </span>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

TrainingCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    training_provider: PropTypes.string,
    soc_name: PropTypes.string,
    duration: PropTypes.number,
    link: PropTypes.string,
  }),
  onButtonClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  onDislikeClick: PropTypes.func,
  rating: PropTypes.string,
  ratingsPage: PropTypes.bool,
};

function capitalizeFirstLetter(sentence) {
  const finalSentence = `${sentence}`
    .toLocaleLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  return finalSentence;
}
