import PropTypes from 'prop-types';

const CareerVideo = ({ title, soc_code, url }) => {
  const base_url = url.substring(0, url.length - 4);
  const caption_url = base_url.replace('OccVids/OccupationVideos', 'CaptionFiles');
  return url ? (
    <video
      src={url}
      poster={base_url + '.jpg'}
      id={`career_video_${soc_code}`}
      title={title}
      controls={true}
      controlsList="nodownload"
      crossOrigin="anonymous"
    >
      <track
        src={caption_url + '.vtt'}
        id={`career_video_transcript_en_${soc_code}`}
        label="English"
        kind="subtitles"
        srcLang="en"
        default={true}
      />
      <track
        src={caption_url + '_es.vtt'}
        id={`career_video_transcript_es_${soc_code}`}
        label="Español"
        kind="subtitles"
        srcLang="es"
      />
    </video>
  ) : null;
};

CareerVideo.propTypes = {
  title: PropTypes.string.isRequired,
  soc_code: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CareerVideo;
