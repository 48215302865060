import PropTypes from 'prop-types';

/* Material UI and other UI Dependencies */
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

/* UI Components */

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const totalSteps = 5;

const OnboardingSteps = ({ activeStep }) => {
  return <BorderLinearProgress variant="determinate" value={(activeStep / totalSteps) * 100} />;
};

OnboardingSteps.propTypes = {
  activeStep: PropTypes.number,
};

export default OnboardingSteps;
