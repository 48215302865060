import { useInfiniteQuery, useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { getRatings, updateCareers, updateTrainings, updateJob } from '../../API';

import { useAuth0 } from '@auth0/auth0-react';

export const getRatingsQueryKey = (filters) => [{ entity: 'ratings', ...filters }];

export const useInfiniteRatings = (filters) => {
  const { isAuthenticated } = useAuth0();

  const idQuery = useQuery({
    queryKey: ['/users/me'],
    enabled: isAuthenticated,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const additionalRequests = isAuthenticated && idQuery.isSuccess;
  return useInfiniteQuery({
    queryFn: async ({ queryKey, pageParam = 0 }) => {
      if (!additionalRequests) {
        return [];
      }
      const responseLimit = filters.limit || 10;
      const { filter_by_type, currentTab } = queryKey[0];

      const data = await getRatings([], {
        limit: responseLimit,
        offset: pageParam * 10,
        ...(filter_by_type && filter_by_type.length ? { filter_by_type: filter_by_type.join('|') } : {}),
        ...(currentTab ? { filter_by_rating: currentTab === 'up' ? 1 : -1 } : {}),
      });

      return data.map((rating) => ({ ...rating, ...rating.item }));
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return pages.flat(1).length / 10;
    },
    queryKey: getRatingsQueryKey(filters),
    enabled: additionalRequests,
  });
};

export const useRateItem = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = getRatingsQueryKey(filters);

  return useMutation({
    mutationFn: ({ item, rating }) => {
      switch (item.type) {
        case 'career':
          return updateCareers(item, rating);
        case 'job':
          return updateJob(item, rating);
        case 'training':
          return updateTrainings(item, rating);
        default:
          break;
      }
    },
    onMutate: async ({ item }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousRatings = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old);
        const index = clone.pages.flat(1).findIndex((oldRating) => oldRating.id === item.id);
        const newList = clone.pages.flat(1).filter((_, oldRatingIndex) => oldRatingIndex !== index);
        clone.pages[0] = newList;
        return clone;
      });

      // Return a context object with the snapshotted value
      return { previousRatings };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousRatings);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
