import { useInfiniteQuery } from '@tanstack/react-query';
import { getRecommendedCareers } from 'shared/src/lib/API/careers';

const getCareersQueryKey = (filters) => [{ entity: 'careers', ...filters }];

const paginatedCareersQueryFn = async ({ queryKey, pageParam = 0 }) => {
  const { filter_by_skills, filter_by_tag, filter_by_avg_salary, bright_outlook, filter_by_reqs, all_careers } =
    queryKey[0];
  const data = await getRecommendedCareers([], {
    limit: 10,
    offset: pageParam * 10,
    ...(filter_by_skills && filter_by_skills.length ? { filter_by_skills: filter_by_skills.join('|') } : {}),
    ...(filter_by_tag && filter_by_tag.length ? { filter_by_tag: filter_by_tag.join('|') } : {}),
    ...(filter_by_avg_salary && filter_by_avg_salary.length
      ? { filter_by_avg_salary: filter_by_avg_salary.join('|') }
      : {}),
    ...(filter_by_reqs && filter_by_reqs.length ? { filter_by_reqs: filter_by_reqs.join('|') } : {}),
    ...(bright_outlook ? { bright_outlook: true } : {}),
    ...(all_careers ? { all_careers } : {}),
  });

  return data;
};

export const useInfiniteCareers = (filters) => {
  return useInfiniteQuery({
    queryFn: paginatedCareersQueryFn,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return pages.flat(1).length / 10;
    },
    queryKey: getCareersQueryKey(filters),
  });
};
