import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { apiRequest } from '../../API/wrapper';

const queryKey = ['/users/me'];

const updateUser = async (updatedUserData) => {
  return new Promise((resolve, reject) => {
    apiRequest('put', '/users/me', { data: updatedUserData })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'updateSkillsRating: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'updateSkillsRating: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'updateSkillsRating: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          console.error(errorMessage);
          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('updateSkillsRating: No response was received from the server');
          reject(new Error('updateSkillsRating: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('updateSkillsRating', error.message);
          reject(error);
        }
      });
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedUserData) => {
      return updateUser(updatedUserData);
    },
    onMutate: async (updatedUserData) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old) || {};

        return { ...clone, ...updatedUserData };
      });

      // Return a context object with the snapshotted value
      return { previousValue };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousValue);
    },
  });
};
