import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getRecommendedJobs, updateJob } from 'shared/src/lib/API/jobs';

const getJobsQueryKey = (filters) => [{ entity: 'jobs', ...filters }];

export const getFilterParams = (filters) => {
  const {
    filter_by_name,
    filter_by_soc,
    filter_by_skills,
    filter_by_org,
    filter_by_avg_salary,
    salary_min,
    filter_by_city,
    sort_by,
    sort_asc,
    all_jobs,
  } = filters;

  return {
    ...(filter_by_name && filter_by_name.length ? { filter_by_name } : {}),
    ...(filter_by_soc && filter_by_soc.length ? { filter_by_soc: filter_by_soc.join('|') } : {}),
    ...(filter_by_skills && filter_by_skills.length ? { filter_by_skills: filter_by_skills.join('|') } : {}),
    ...(filter_by_org && filter_by_org.length ? { filter_by_org: filter_by_org.join('|') } : {}),
    ...(filter_by_avg_salary && filter_by_avg_salary.length
      ? { filter_by_avg_salary: filter_by_avg_salary.join('|') }
      : {}),
    ...(salary_min ? { salary_min } : {}),
    ...(filter_by_city && filter_by_city.length ? { filter_by_city: filter_by_city.join('|') } : {}),
    ...(sort_by ? { sort_by, sort_asc } : {}),
    ...(all_jobs ? { all_jobs } : {}),
  };
};

const paginatedJobsQueryFn = async ({ queryKey, pageParam = 0 }) => {
  const data = await getRecommendedJobs([], {
    limit: 9,
    offset: pageParam * 9,
    ...getFilterParams(queryKey[0]),
  });
  return data;
};

export const useInfiniteJobs = (filters) => {
  return useInfiniteQuery({
    queryFn: paginatedJobsQueryFn,
    retry: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.jobs?.length < 9) {
        return undefined;
      }
      return pages.flat(1).length;
    },
    queryKey: getJobsQueryKey(filters),
  });
};

export const useRateJob = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = getJobsQueryKey(filters);

  return useMutation({
    mutationFn: ({ job, rating }) => {
      return updateJob(job, rating);
    },
    onMutate: async ({ job, rating }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousJobs = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old);
        clone.pages.forEach((page, pageIndex) => {
          page.jobs?.forEach((oldJob, oldJobIndex) => {
            if (oldJob.guid === job.guid) {
              clone.pages[pageIndex].jobs[oldJobIndex].rating = rating;
            }
          });
        });

        return clone;
      });
      // Return a context object with the snapshotted value
      return { previousJobs };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousJobs);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
