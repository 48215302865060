/*-- Routing --*/
import { Route, Switch, Redirect } from 'react-router-dom';

/* Redux */
import { useSelector } from 'react-redux';

/*-- Layout --*/
import RecommendationsHeader from 'components/RecommendationsHeader';

/*-- Pages --*/
import LoadingRecommendations from 'components/LoadingRecommendations';
import Careers from 'components/Careers';
import Jobs from 'components/Jobs';
import Training from 'components/Training';
import Profile from 'components/Profile';
import ExperienceWallet from 'components/ExperienceWallet';
import Ratings from 'components/Ratings';

import JobSearchActivity from 'components/JobSearchActivity';

const AuthenticatedPagesSwitch = () => {
  const { loadingRecommendations, user_settings, urlPrefix } = useSelector((state) => state.app);

  return loadingRecommendations ? (
    <LoadingRecommendations />
  ) : (
    <>
      <RecommendationsHeader />
      <Switch>
        <Route path={[`/careers`, `/:lang/careers`]}>
          <Careers />
        </Route>
        <Route path={[`/jobs`, `/:lang/jobs`]}>
          <Jobs />
        </Route>
        <Route path={[`/training`, `/:lang/training`]}>
          <Training />
        </Route>
        <Route path={[`/experience`, `/:lang/experience`]}>
          <ExperienceWallet />
        </Route>
        <Route path={['/job-search-activity', '/:lang/job-search-activity']}>
          {user_settings.add_job_search_activity_hero_button ? (
            <JobSearchActivity />
          ) : (
            <Redirect to={`${urlPrefix}/jobs`} />
          )}
        </Route>
        <Route path={[`/saved-items`, `/:lang/saved-items`]}>
          <Ratings />
        </Route>
        <Route path={[`/hidden-items`, `/:lang/hidden-items`]}>
          <Ratings />
        </Route>
        <Route path={[`/my-profile`, `/:lang/my-profile`]}>
          <Profile />
        </Route>
        <Route path={['/onboarding', '/:lang/onboarding']}>
          <Redirect to={`${urlPrefix}/jobs`} />
        </Route>
        <Route>
          <Redirect to={`${urlPrefix}/jobs`} />
        </Route>
      </Switch>
    </>
  );
};

export default AuthenticatedPagesSwitch;
