import { createSlice } from '@reduxjs/toolkit';
import { defaultLanguage } from 'i18n/config';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    drawerOpen: false,
    loading: true,
    loadingRecommendations: false,
    loadingLanguage: false,
    needsContactInfo: false,
    needsOnboarding: false,
    contactUpdate: false,
    hasSsn: false,
    preferredName: '',
    firstName: '',
    lastName: '',
    unrecoverableError: false,
    newHero: true,
    locale: defaultLanguage,
    user_settings: {},
    chat_welcome_shown: false,
    survey: {
      completed: false,
      loaded: false,
    },
    urlPrefix: '',
    onboarding_guid: {},
    newUser: false,
    forceMobileMenuOpen: false,
    omnitrak_modal_shown: false,
  },
  reducers: {
    setNewUser: (state, action) => {
      state.newUser = action.payload;
    },
    setForceMobileMenuOpen: (state, action) => {
      state.forceMobileMenuOpen = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingRecommendations: (state, action) => {
      state.loadingRecommendations = action.payload;
    },
    setLoadingLanguage: (state, action) => {
      state.loadingLanguage = action.payload;
    },
    setNeedsContactInfo: (state, action) => {
      state.needsContactInfo = action.payload;
    },
    setNeedsOnboarding: (state, action) => {
      state.needsOnboarding = action.payload;
    },
    setContactUpdate: (state, action) => {
      state.contactUpdate = action.payload;
    },
    setName: (state, action) => {
      const { first_name, last_name } = action.payload;
      state.firstName = first_name;
      state.lastName = last_name;
    },
    setPreferredName: (state, action) => {
      state.preferredName = action.payload;
    },
    setUnrecoverableError: (state, action) => {
      state.unrecoverableError = action.payload;
    },
    setUserSettings: (state, action) => {
      state.user_settings = action.payload;
    },
    setSurvey: (state, action) => {
      state.survey = action.payload;
    },
    setWelcomeShown: (state, action) => {
      state.chat_welcome_shown = action.payload;
    },
    setHasSsn: (state, action) => {
      state.hasSsn = action.payload;
    },
    setOnboardingGuid: (state, action) => {
      state.onboarding_guid = action.payload;
    },
    setOmnitrakModalShown: (state, action) => {
      state.omnitrak_modal_shown = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setLoadingRecommendations,
  setLoadingLanguage,
  setNeedsContactInfo,
  setSurvey,
  setWelcomeShown,
  setNeedsOnboarding,
  setName,
  setPreferredName,
  setUnrecoverableError,
  setLocale,
  setUserSettings,
  setHasSsn,
  setContactUpdate,
  setOnboardingGuid,
  setNewUser,
  setForceMobileMenuOpen,
  setOmnitrakModalShown,
} = appSlice.actions;

export default appSlice.reducer;
