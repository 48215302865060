import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLoading, setNeedsContactInfo, setNeedsOnboarding } from 'app/slices/appSlice';
import { setNewUser } from 'app/slices/appSlice';

/* Material UI and other UI Dependencies */
import { Typography, useMediaQuery } from '@mui/material';
import OnboardingSteps from 'components/OnboardingSteps';
import { Box } from '@mui/material';

const BouncingDotsLoader = () => {
  return (
    <Box
      className="bouncing-loader"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        '& div': {
          width: '16px',
          height: '16px',
          margin: '3px 6px',
          borderRadius: '50%',
          backgroundColor: '#a3a1a1',
          opacity: 1,
          animation: 'bouncing-loader 0.6s infinite alternate',
        },
      }}
    >
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
    </Box>
  );
};

export default function OnboardingGoals() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const urlPrefix = getUrlPrefix(intl.locale);
  const isSmall = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    dispatch(setNewUser(true));
    setTimeout(() => {
      dispatch(setNeedsOnboarding(false));
      dispatch(setNeedsContactInfo(false));
      dispatch(setLoading(false));
      history.push(`${urlPrefix}/jobs`);
    }, 3000);
  }, []);

  return (
    <Box sx={{ boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.1)' }}>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom sx={{ fontSize: 24, mt: 1 }}>
            {intl.formatMessage({ id: 'onboarding.titleLoading' }, { step: 5, totalSteps: 5 })}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 222, marginLeft: 'auto', marginRight: 'auto', mt: 4, mb: 1 }}>
          <OnboardingSteps activeStep={5} />
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />

      <div className={onboardingStyles.goalsContainer}>
        <Box mb={4} />
        <BouncingDotsLoader />
        <Box mb={2} />
        <Box style={{ textAlign: 'center', lineHeight: '22px' }} pt={1} {...(isSmall ? null : { px: 5 })}>
          {intl.formatMessage({ id: 'onboarding.goals.youCanChange' })}
        </Box>
        <Box mb={4} />
      </div>
    </Box>
  );
}
