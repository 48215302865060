import { useEffect, forwardRef, Children, isValidElement, cloneElement } from 'react';
import { TextField, Box, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { useSkills } from 'shared/hooks';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setSkills } from 'app/slices/skillsSlice';

/* Styles */
import CheckIcon from '@mui/icons-material/Check';

import ExperienceWalletCustomAutocomplete from './ExperienceWalletCustomAutocomplete';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const ListboxComponent = forwardRef((props, ref) => {
  const { children, role, ...other } = props;
  const items = Children.toArray(children);
  const itemCount = items.length;
  const itemSize = 40;
  const listHeight = itemSize * itemCount;

  return (
    <div ref={ref}>
      <Box {...other} sx={{ '& div': { width: '100% !important;', maxWidth: '100% !important;' } }}>
        <List
          height={Math.min(listHeight, 250)}
          width={300}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(listRowProps) => {
            if (isValidElement(items[listRowProps.index])) {
              return cloneElement(items[listRowProps.index], {
                style: listRowProps.style,
              });
            }
            return null;
          }}
          role={role}
        />
      </Box>
    </div>
  );
});

ListboxComponent.displayName = 'ListboxComponent';
ListboxComponent.propTypes = {
  role: PropTypes.string,
  children: PropTypes.any,
};

export default function ExperienceWalletSkillsAutocomplete({
  isDisabled,
  value,
  onChange,
  error,
  helperText,
  open,
  setOpen,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state.skills);
  const skillsQuery = useSkills();

  useEffect(() => {
    if (skillsQuery.isSuccess) {
      dispatch(setSkills(skillsQuery.data));
    }
  }, [skillsQuery.isSuccess, skillsQuery.data, skillsQuery.status]);

  return (
    <ExperienceWalletCustomAutocomplete
      ListboxComponent={ListboxComponent}
      multiple
      disableClearable
      disableCloseOnSelect={true}
      id={'add-skills'}
      options={skills}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={isDisabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          paddingTop: '4px!important',
        },
        '& .MuiAutocomplete-input': {
          fontSize: 12,
        },
      }}
      renderOption={(props, option, { selected }) => {
        const walletIds = value.filter((sk) => sk.wallet).map((sk) => sk.id);
        const isWallet = walletIds.includes(option.id);
        return (
          <Box
            {...props}
            sx={{
              margin: '2px 0',
              padding: '6px 16px',
              position: 'relative',
              pointerEvents: isWallet ? 'none' : 'auto',
              '&[aria-selected="true"]': {
                backgroundColor: 'rgba(41, 125, 176, 0.12)',
              },
              '&.Mui-focused': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              ...(isWallet ? { opacity: 0.7, backgroundColor: '#f5f5f5' } : {}),
            }}
          >
            {selected && !isWallet ? (
              <CheckIcon fontSize="small" sx={{ position: 'absolute', left: 11, top: 10 }} />
            ) : null}
            <Box component="span" sx={{ pl: 2.5, display: 'inline-block', fontSize: 14, fontWeight: 300 }}>
              {option.name}
            </Box>
          </Box>
        );
      }}
      renderTags={(value, getTagProps) => (
        <div style={{ width: '100%' }}>
          {value.map((option, index) => {
            const { className, ...rest } = getTagProps({ index }); //eslint-disable-line

            const fromWallet = value.filter((sk) => sk.wallet);
            const fromWalletIds = fromWallet.map((sk) => sk.id);

            return (
              <Chip
                key={option.id}
                size="small"
                sx={{ mr: 1, mt: 1 }}
                icon={option.wallet ? <AccountBalanceWalletIcon /> : null}
                color={option.wallet ? 'primary' : 'secondary'}
                label={option.name}
                {...rest}
                onDelete={option.wallet ? null : rest.onDelete}
                disabled={isDisabled || fromWalletIds.indexOf(option.id) !== -1}
              />
            );
          })}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          placeholder={intl.formatMessage({ id: 'forms.onboarding.education.skillsLabel' })}
          inputProps={{
            ...params.inputProps,
            'aria-label': 'skills',
          }}
          helperText={helperText}
          error={error}
          sx={{
            '& input::placeholder': {
              color: 'rgba(0,0,0,0.87)',
              opacity: 1,
            },
          }}
        />
      )}
    />
  );
}

ExperienceWalletSkillsAutocomplete.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
