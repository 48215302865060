/* Styles */
import makeStyles from '@mui/styles/makeStyles';

/* Material UI and other UI Dependencies */
import { CircularProgress, Box } from '@mui/material';

function CustomCircularProgress() {
  return <CircularProgress size={120} thickness={4} />;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CustomCircularProgress />
    </div>
  );
}

export const LoadingSmall = () => {
  return (
    <Box m={2} display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};
