import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUseES = () => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    return () => {
      console.log('Leaving Terms of Use');
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            Condiciones de Uso y Política de Privacidad
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>Bienvenido a Arkansas LAUNCH.</p>
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estamos tomando todas las precauciones, incluyendo hacer nuestros mejores esfuerzos para cumplir con todas
            las leyes y reglamentos estatales y federales pertinentes relacionados con la privacidad, para garantizar
            que su información esté lo más segura posible.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al visitar y utilizar este sitio web, sus subpáginas y contenidos (conocidos colectivamente como este sitio
            web), usted acepta las Condiciones de uso y la Política de privacidad.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Requisitos
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              Al utilizar este sitio web, usted garantiza que es mayor de 18 años y que tiene autoridad legal para
              aceptar estas Condiciones de uso.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Uso de la información
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            El Departamento de Transformación y Servicios Compartidos de Arkansas y/o sus agentes/contratistas/socios
            utilizarán los registros del Estado de Arkansas y la información que usted proporcione cuando se registre en
            LAUNCH para conectarlo con oportunidades personalizadas de empleo y formación.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            También podemos utilizar y compartir la información proporcionada según sea razonablemente necesario para
            cumplir con la ley o un proceso legal (incluyendo una orden o citación judicial o gubernamental); para
            detectar, prevenir o abordar de otro modo el fraude, la seguridad o los problemas técnicos; para hacer
            cumplir las Condiciones de uso y la Política de privacidad; para proteger los derechos, la propiedad o la
            seguridad del Estado de Arkansas, nuestros usuarios y/o el público; o de otro modo con su consentimiento
            expreso.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al inscribirse en LAUNCH, acepta que el Departamento y/o sus contratistas utilicen la información anterior,
            así como cualquier dato asociado que haya puesto a disposición del Departamento, para crear recomendaciones
            personalizadas de empleo y oportunidades profesionales para usted. Asimismo, acepta que el Departamento y/o
            sus contratistas y socios se pongan en contacto con usted y/o le envíen comunicaciones que pueden incluir,
            entre otras cosas: información importante sobre oportunidades de contratación laboral, información sobre
            puestos de trabajo disponibles, oportunidades de capacitación profesional, oportunidades de educación y
            otros recursos y oportunidades relacionados con sus capacidades.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al proporcionar su número de teléfono celular, usted acepta específicamente que el Departamento le envíe
            comunicaciones a su teléfono celular que pueden incluir, entre otras cosas: información importante sobre los
            programas de Seguro de Desempleo, oportunidades de contratación laboral que pueden ser adecuadas para usted,
            información sobre empleos disponibles que pueden ser adecuados para usted y oportunidades de capacitación
            disponibles.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Usted puede elegir permitir que el Departamento comparta su información de contacto directamente con
            empleadores y/o reclutadores que puedan estar interesados en contratarlo en base a sus habilidades,
            intereses y experiencia. Si elige este servicio, acepta que el Departamento y/o sus agentes/contratistas
            compartan su información de contacto, historial de empleo y formación, y una recomendación sobre su
            idoneidad para un puesto de trabajo o programa de capacitación directamente con empleadores y agencias de
            contratación, colocación y gubernamentales de Arkansas con el único fin de ponerse en contacto con usted
            sobre oportunidades de contratación o capacitación que puedan ser adecuadas para sus habilidades y
            experiencia.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Aviso de seguridad y vigilancia
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Si navega por este sitio web para leer, imprimir o descargar información, es posible que se recopile
            información de navegación.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Por motivos de seguridad del sitio, todo el tráfico de la red se controla con el fin de identificar intentos
            no autorizados de cargar o modificar información, o de llevar a cabo actividades delictivas. La información
            de navegación puede utilizarse para obtener datos personales en el marco de investigaciones policiales
            autorizadas o de procesos judiciales.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, para proteger este sitio web, así como el sistema informático asociado que lo soporta, de usos no
            autorizados y para garantizar que el sistema informático funciona correctamente, las personas que accedan a
            este sitio web y al sistema informático asociado están sujetas a que todas sus actividades sean monitoreadas
            y grabadas por personal autorizado para ello por el Estado de Arkansas. Cualquier persona que utilice este
            sitio web y el sistema informático asociado consiente expresamente dicho monitoreo y se le informa de que si
            este revela pruebas de un posible abuso o actividad criminal, el personal del Estado de Arkansas puede
            proporcionar los resultados de dicho monitoreo a los agentes apropiados. Los intentos no autorizados de
            cargar o cambiar el contenido del sitio web, o de causar daños de cualquier otro modo a este sitio web o al
            sistema informático están estrictamente prohibidos y podrán ser castigados por la legislación aplicable.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Modificaciones sin previo aviso
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web es dinámico y cambiará con el tiempo sin previo aviso. Por lo tanto, los usuarios de este
            sitio web son plenamente responsables de verificar por sí mismos la exactitud, integridad, actualidad e
            idoneidad de los contenidos encontrados en el sitio web.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, nos reservamos el derecho a modificar las Condiciones de uso y la Política de privacidad en
            cualquier momento. Le recomendamos que revise los periódicamente. El uso continuo de LAUNCH tras cualquier
            modificación del sitio web, las Condiciones de uso y la Política de privacidad constituye su aceptación de
            dichas modificaciones.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Exclusión de garantías
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web se proporciona “TAL CUAL” y sin garantías de ningún tipo. En la máxima medida permitida por
            la ley, el Estado de Arkansas, incluyendo cada agencia, funcionario o empleado del Estado de Arkansas
            renuncia a todas las garantías, expresas o implícitas, entre las que se incluyen las garantías de
            comerciabilidad y adecuación para un propósito particular, con respecto a este sitio web; esta exclusión de
            garantías incluye, entre otros aspectos, las renuncias con respecto a lo siguiente: (1) que el
            funcionamiento de este sitio web será ininterrumpido y libre de errores; (2) que cualquier defecto u otro
            error en este sitio web será corregido; y (3) que este sitio web así como el sistema informático están o
            estarán libres de virus u otros componentes dañinos.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, ni el Estado de Arkansas ni ninguna agencia, funcionario o empleado del Estado de Arkansas hace
            ninguna representación, garantía o promesa en cuanto a: (1) la exactitud, integridad, vigencia o idoneidad
            de la información proporcionada a través de este sitio web; (2) el uso o los resultados del uso de este
            sitio web; y (3) la exactitud, confiabilidad, disponibilidad o integridad o utilidad del contenido de los
            sitios web creados y mantenidos por personas distintas del Estado de Arkansas y vinculados a o desde este
            sitio web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Limitación de responsabilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Bajo ninguna circunstancia, incluidos, a mero título enunciativo, negligencia, el Estado de Arkansas ni
            ninguna agencia, funcionario o empleado del Estado de Arkansas será responsable de ningún daño especial,
            consecuente y/o incidental que pueda resultar del uso o la incapacidad de acceder o usar este sitio web,
            incluso si el Estado de Arkansas ha sido advertido de la posibilidad de tales daños. Es posible que la
            legislación aplicable no permita la limitación o exclusión de responsabilidad o de daños incidentales o
            consecuentes, por lo que la limitación o exclusión anterior puede no aplicarse en su caso. En ningún caso la
            responsabilidad total del Estado de Arkansas hacia usted por todos los daños, pérdidas y causas de acción
            (ya sea en contrato, agravio (incluyendo, pero no limitado a negligencia, o de otra manera) excederá la
            cantidad pagada por usted, si fuere el caso, para acceder a este sitio web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Aviso de derechos de autor
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Queda prohibida la reproducción o utilización de cualquier contenido de este sitio web con fines comerciales
            o de cualquier forma que pueda dar la impresión de aprobación oficial por parte del Estado de Arkansas.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Enlaces a sitios y páginas web externos
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              Este sitio web puede contener enlaces de hipertexto a sitios y páginas externas que contienen información
              creada y mantenida por organizaciones públicas y privadas distintas del Estado de Arkansas. Estos enlaces
              de hipertexto pueden ser creados por el Estado de Arkansas si determina que el establecimiento del enlace
              externo será acorde con la ayuda o el fomento del propósito de este sitio web, que es promover de forma
              significativa y diligente el servicio público a los ciudadanos y las empresas mediante:
            </p>
            <ul>
              <li>
                la ampliación del acceso de las empresas y los ciudadanos a los servicios e información gubernamentales{' '}
              </li>
              <li>
                la oferta de un proceso fácil y cómodo para que las empresas y los ciudadanos realicen trámites en línea
                con el gobierno del estado;
              </li>
              <li>
                la aceleración del desarrollo y la prestación de un mayor volumen de servicios públicos en línea de
                calidad;
              </li>
              <li>la mejora del nivel de atención al cliente del gobierno del estado; y </li>
              <li>
                la ampliación de los servicios electrónicos gubernamentales a los ciudadanos de las ciudades y el
                gobierno de los condados.{' '}
              </li>
            </ul>
            <p>
              Además, el Estado de Arkansas puede crear enlaces de hipertexto con fines informativos, es decir, cuando
              el sitio web externo enlazado proporcione información útil y valiosa a los visitantes de este sitio web, o
              cuando el sitio web externo enlazado esté obligado o autorizado por la ley.
            </p>
            <p>
              El Estado de Arkansas, a su entera discreción, determinará si el sitio web externo se ajusta a la
              finalidad de este sitio web o a los fines informativos especificados. La inclusión de un enlace de
              hipertexto a un sitio web externo no supone la aprobación de ningún producto o servicio ofrecido o
              mencionado en el sitio web enlazado, de las organizaciones que patrocinan dicho sitio web ni de ninguna
              opinión que pueda expresarse o referirse en el sitio web.
            </p>
            <p>
              Estos enlaces de hipertexto a sitios y páginas externas pueden ser eliminados o sustituidos a discreción
              del Estado de Arkansas, en cualquier momento y sin previo aviso.
            </p>
            <p>
              En caso de que descubra problemas o tenga dudas sobre el formato, la exactitud, la fecha de actualización
              o la integridad de un sitio web externo enlazado, por favor, póngase en contacto con la organización
              responsable del sitio web externo enlazado – el Estado de Arkansas no controla ni es responsable de ningún
              sitio o página externos enlazados.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Descargo de responsabilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Cualquier referencia en este sitio web a productos, procesos o servicios comerciales específicos por su
            nombre comercial, marca registrada, fabricante u otro, no constituye ni implica su aprobación, recomendación
            o preferencia por parte del Estado de Arkansas.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Jurisdicción
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Toda persona que decida acceder a este sitio web y utilizarlo se somete a la jurisdicción del Estado de
            Arkansas.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estos términos se regirán e interpretarán de acuerdo con las leyes del Estado de Arkansas. Cualquier disputa
            que surja de o en relación con estos términos, o el acceso y uso de este sitio web se decidirá bajo las
            leyes de y en los tribunales estatales en el Estado de Arkansas, y por la presente usted consiente y se
            somete a la jurisdicción personal de dichos tribunales con el fin de adjudicar dicha disputa.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Otros Sitios Web
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web puede contener enlaces de hipertexto a otros sitios y páginas web que contienen información
            creada y mantenida por otras organizaciones públicas y privadas. Si decide visitar estos sitios enlazados,
            debe revisar las declaraciones de privacidad de estos otros sitios web. El Estado de Arkansas no es
            responsable del contenido de estos otros sitios web
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Divisibilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Si alguna disposición de las Condiciones de uso y la Política de privacidad anteriores se considerase
            ilegal, nula o inaplicable por un tribunal de jurisdicción competente, dicha disposición se considerará
            divisible de los términos restantes y no afectará a la validez y aplicabilidad de las disposiciones
            restantes.
          </Typography>
          <Box m={3} />
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseES;
