import PropTypes from 'prop-types';
import { Chip, Stack } from '@mui/material';

export default function ResultsSummary({ items }) {
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
      {items.map((item) => (
        <Chip
          key={item.label}
          icon={<item.Icon />}
          onClick={item.onClick ?? null}
          label={<span id={item.id}>{`${item.value} ${item.label}`}</span>}
        />
      ))}
    </Stack>
  );
}

ResultsSummary.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};
