import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { grey } from '@mui/material/colors';
import LocalLibraryTwoToneIcon from '@mui/icons-material/LocalLibraryTwoTone';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { apiRequest } from 'shared/API';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import {
  setNameFilterValue,
  setCareerPathsFilterValues,
  setSkillsFilter,
  setProvidersFilter,
  setDurationFilterValues,
  setLocationsFilterValues,
  setPreReqsFilterValues,
  setSortBy,
  setFilterOptions,
  setShowRecommendedTrainings,
} from 'app/slices/trainingSlice';
import { useInfiniteTrainings, useRateTraining } from 'lib/hooks/trainings';

/* Utils */
import { debounce } from 'lib/performance';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Button,
  MenuItem,
  Alert,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { List, ListItem, Drawer, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { CustomNotification, JobNotificationContents } from 'shared/components';
import SkillsMultiselectFilter from 'shared/src/components/SkillsMultiselectFilter';
import MultiselectFilter from 'shared/src/components/MultiselectFilter';
import TrainingCard from 'shared/src/components/TrainingCard';
import ResultsSummary from 'shared/src/components/ResultsSummary';

/* Icons */
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import ComputerIcon from '@mui/icons-material/Computer';

import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

const Training = () => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const debouncedSetKeywordFilter = debounce((evt) => dispatch(setNameFilterValue(evt?.target?.value)), 400);
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const [filtersOpen, setFiltersOpen] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    filterByNameValue,
    careerPathsFilterValues,
    skillsFilter,
    providersFilter,
    durationFilterValues,
    locationsFilterValues,
    preReqsFilterValues,
    sortBy,
    showRecommendedTrainings,
    filterOptions: { careerPaths, locations, providers, skills },
  } = useSelector((state) => state.training);

  const { loadingLanguage } = useSelector((state) => state.app);

  const filters = {
    filter_by_name: filterByNameValue,
    filter_by_soc: careerPathsFilterValues,
    filter_by_skills: skillsFilter,
    filter_by_provider: providersFilter,
    filter_by_duration: durationFilterValues,
    filter_by_county: locationsFilterValues,
    filter_by_prereqs: preReqsFilterValues,
    sort_by: sortBy,
    all_trainings: !showRecommendedTrainings,
  };

  const { isSuccess, data, isFetching, isFetched, hasNextPage, fetchNextPage } = useInfiniteTrainings(filters);
  const { isSuccess: isDefaultSucces, data: defaultData } = useInfiniteTrainings({});

  const [loadedFirstTime, setLoadedFirstTime] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setLoadedFirstTime(true);
    }
  }, [isSuccess]);

  const filtersQuery = useQuery({
    queryKey: ['/users/me/trainings_filters', showRecommendedTrainings],
    queryFn: async ({ queryKey }) =>
      apiRequest('GET', queryKey[0], {
        params: { ...(!showRecommendedTrainings ? { all_trainings: !showRecommendedTrainings } : {}) },
      }),
    onSuccess: (data) => {
      dispatch(
        setFilterOptions({
          locations: data.counties,
          careerPaths: data.career_paths,
          providers: data.training_providers,
          skills: data.skills,
        })
      );
    },
  });
  const rateTrainingMutation = useRateTraining(filters);

  const filtersActive =
    !filtersQuery.isLoading &&
    (filterByNameValue.length > 0 ||
      careerPathsFilterValues.length > 0 ||
      skillsFilter.length > 0 ||
      providersFilter.length > 0 ||
      durationFilterValues.length > 0 ||
      locationsFilterValues.length > 0 ||
      preReqsFilterValues.length > 0);
  const filtersCount =
    (filterByNameValue.length > 0 ? 1 : 0) +
    [
      careerPathsFilterValues,
      skillsFilter,
      providersFilter,
      durationFilterValues,
      locationsFilterValues,
      preReqsFilterValues,
    ].reduce((acc, curr) => acc + curr.length, 0);

  const responses = isSuccess ? data.pages.reduce((acc, page) => acc.concat(page.trainings), []) : [];
  const summary = isSuccess ? data.pages[0] : {};

  const showMore = () => {
    trackEvent('TRAININGS_MORE');
    fetchNextPage();
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 400 ||
      isFetching ||
      !hasNextPage
    ) {
      return;
    }
    showMore();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching]);

  const clearFilters = () => {
    dispatch(setNameFilterValue(''));
    dispatch(setCareerPathsFilterValues([]));
    dispatch(setSkillsFilter([]));
    dispatch(setProvidersFilter([]));
    dispatch(setDurationFilterValues([]));
    dispatch(setLocationsFilterValues([]));
    dispatch(setPreReqsFilterValues([]));
    dispatch(setSortBy(''));
  };

  const handleGlobalLikeClick = (training) => () => {
    const newRating = training.rating === 1 ? 0 : 1;
    rateTrainingMutation.mutate(
      { training, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          if (newRating === 0) return;
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="like" intl={intl} history={history} redirect="saved-items" />
              </CustomNotification>
            ),
          });
        },
      }
    );
    trackEvent('TRAINING_GLOBAL_LIKE', training);
  };

  const handleGlobalDislikeClick = (training) => () => {
    const newRating = training.rating === -1 ? 0 : -1;
    rateTrainingMutation.mutate(
      { training, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          if (newRating === 0) return;
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                {intl.formatMessage(
                  { id: 'ratings.dislikedItem' },
                  {
                    link: (
                      <Typography
                        component="span"
                        sx={{
                          pb: 0.5,
                          '&:active': { color: grey[100] },
                          fontSize: '0.875rem',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => history.push(`${urlPrefix}/hidden-items`)}
                      >
                        {intl.formatMessage({ id: 'ratings.dislikedItem.here' })}
                      </Typography>
                    ),
                  }
                )}
              </CustomNotification>
            ),
          });
        },
      }
    );
    trackEvent('TRAINING_GLOBAL_DISLIKE', training);
  };

  const handleGlobalApplyClick = (item) => () => {
    trackEvent('TRAINING_GLOBAL_APPLY', item);
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'layout.menu.trainingRecommendations' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TRAINING');
    TimeMe.startTimer();
    return () => {
      console.log('Leaving Training');
    };
  }, []);

  const noResults = !loadingLanguage && isFetched && responses.length === 0;
  const showResults = !loadingLanguage && isFetched && responses && responses.length > 0;
  const showNoResults = !loadingLanguage && isFetched && responses && responses.length === 0 && isDefaultSucces;

  const FiltersList = (
    <Box sx={{ width: 264, px: 1.5 }} role="presentation">
      <List>
        <ListItem sx={{ pt: 1.5, pb: 1, pl: 0 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.filtersButton' })}</Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.sortBy' })}</Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: 'rgba(0, 0, 0, 0.87)',
            li: { width: '100%' },
          }}
        >
          <MenuItem
            onClick={() => dispatch(setSortBy(sortBy === 'title' ? '' : 'title'))}
            selected={sortBy === 'title'}
            id="learning-opportunity-a-z-training-link"
          >
            {intl.formatMessage({ id: 'training.filters.sortByTitle' })}
          </MenuItem>
          <MenuItem
            onClick={() => dispatch(setSortBy(sortBy === 'training_provider' ? '' : 'training_provider'))}
            selected={sortBy === 'training_provider'}
            id="provider-a-z-training-link"
          >
            {intl.formatMessage({ id: 'training.filters.sortByProvider' })}
          </MenuItem>
          <MenuItem
            onClick={() => dispatch(setSortBy(sortBy === 'duration' ? '' : 'duration'))}
            selected={sortBy === 'duration'}
            id="duration-short-long-training-link"
          >
            {intl.formatMessage({ id: 'training.filters.sortByDuration' })}
          </MenuItem>
          <MenuItem
            onClick={() => dispatch(setSortBy(sortBy === 'city' ? '' : 'city'))}
            selected={sortBy === 'city'}
            id="location-a-z-training-link"
          >
            {intl.formatMessage({ id: 'training.filters.sortByLocation' })}
          </MenuItem>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem sx={{ px: 0.5 }}>
          <FormControlLabel
            sx={{ ml: 0 }}
            labelPlacement="start"
            control={
              <Switch
                checked={showRecommendedTrainings}
                onChange={() => {
                  clearFilters();
                  dispatch(setShowRecommendedTrainings(!showRecommendedTrainings));
                }}
              />
            }
            label={intl.formatMessage({ id: 'training.filters.showRecommendedTrainings' })}
          />
        </ListItem>
        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'training.filters.name' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <TextField
            defaultValue={filterByNameValue}
            onChange={debouncedSetKeywordFilter}
            sx={{ '& input': { py: '11px' } }}
            label={intl.formatMessage({ id: 'training.filters.name' })}
            id="keyword-search-training"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'careers.filter.careers' })} ({careerPaths.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'jobs.filters.careers' })}
            options={careerPaths.map((car) => ({ value: car.soc, label: car.title }))}
            value={careerPathsFilterValues}
            onChange={(newValues) => dispatch(setCareerPathsFilterValues(newValues))}
            id="career-path-training-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'experience.drawer.skillPlural' })} ({skills.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <SkillsMultiselectFilter
            value={skillsFilter}
            enabledSkills={skills}
            onChange={(newValues) => dispatch(setSkillsFilter(newValues))}
            id="skills-training-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'training.filters.provider' })} ({providers.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'training.filters.filterByProvider' })}
            options={providers.map((provider) => ({ value: provider, label: provider }))}
            value={providersFilter}
            onChange={(newValues) => dispatch(setProvidersFilter(newValues))}
            id="provider-training-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'experience.form.length' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'training.filters.filterByDuration' })}
            options={[
              {
                value: 'weeks_3months',
                label: `${capFirstLetter(intl.formatMessage({ id: 'training.weeks' }))}-3 ${intl.formatMessage({
                  id: 'training.duration.months',
                })}`,
              },
              {
                value: '4_6_months',
                label: `4-6 ${intl.formatMessage({
                  id: 'training.duration.months',
                })}`,
              },
              {
                value: '6_12_months',
                label: `6-12 ${intl.formatMessage({
                  id: 'training.duration.months',
                })}`,
              },
              {
                value: '12_24_months',
                label: `12-24 ${intl.formatMessage({
                  id: 'training.duration.months',
                })}`,
              },
              {
                value: '24_plus_months',
                label: `24+ ${intl.formatMessage({
                  id: 'training.duration.months',
                })}`,
              },
            ]}
            value={durationFilterValues}
            onChange={(newValues) => dispatch(setDurationFilterValues(newValues))}
            id="length-training-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'training.filters.location' })} ({locations.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'training.filters.filterByLocation' })}
            options={locations.map((loc) => ({ value: loc, label: loc }))}
            value={locationsFilterValues}
            onChange={(newValues) => dispatch(setLocationsFilterValues(newValues))}
            id="location-training-filter-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'training.filters.preReqs' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'training.filters.filterByPrerequisites' })}
            options={[
              {
                value: 'highschool',
                label: intl.formatMessage({
                  id: 'training.filters.prereq.highSchool',
                }),
              },
              {
                value: 'bachelors',
                label: intl.formatMessage({
                  id: 'training.filters.prereq.bachelors',
                }),
              },
              {
                value: 'advanced',
                label: intl.formatMessage({
                  id: 'training.filters.prereq.advanced',
                }),
              },
              {
                value: 'previous_experience',
                label: intl.formatMessage({
                  id: 'training.filters.prereq.previousExperience',
                }),
              },
            ]}
            value={preReqsFilterValues}
            onChange={(newValues) => dispatch(setPreReqsFilterValues(newValues))}
            id="prerequisite-training-filter-dropdown"
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mt: 6,
          mb: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'flex-end' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Typography variant="h1">
          <LocalLibraryTwoToneIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'training.title' })}
        </Typography>
        {!loadingLanguage && loadedFirstTime && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: { xs: 1, md: 0 }, textTransform: 'none', fontSize: 14 }}
            onClick={() => setFiltersOpen(true)}
            startIcon={<TuneOutlinedIcon />}
          >
            <Typography
              mr={filtersCount > 0 ? 1 : 0}
              component="span"
              color="primary.dark"
              sx={{ fontSize: 14, fontWeight: 500 }}
              id="filter-sort-training-button"
            >
              {intl.formatMessage({ id: 'jobs.filters.filtersButton' })}
            </Typography>
            {filtersCount > 0 ? <span style={{ fontSize: 13 }}>({filtersCount})</span> : null}
          </Button>
        )}
      </Box>
      {!loadingLanguage && isFetched && (
        <ResultsSummary
          items={[
            {
              Icon: RouteOutlinedIcon,
              value: summary.num_careers,
              label: intl.formatMessage({ id: 'jobs.summary.careers' }),
              onClick: () => setFiltersOpen(true),
              id: 'career-paths-training-pill',
            },
            {
              Icon: ComputerIcon,
              value: summary.num_training,
              label: intl.formatMessage({ id: 'training.results.learningOpportunities' }),
            },
          ]}
        />
      )}
      <Divider sx={{ mt: 2, mb: 3 }} />

      {(isFetching && !isFetched) || loadingLanguage || !filtersQuery.isFetched ? (
        <Box m={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      <Grid container spacing={2}>
        {noResults && (
          <Grid item xs={12} mb={1}>
            <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {intl.formatMessage(
                  { id: 'careers.filters.noResults' },
                  {
                    link: (
                      <Typography
                        sx={{
                          color: 'primary.dark',
                          fontWeight: 700,
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={clearFilters}
                        component="span"
                      >
                        {intl.formatMessage({ id: 'careers.filters.noResultsLink' })}
                      </Typography>
                    ),
                  }
                )}
              </Typography>
            </Alert>
          </Grid>
        )}
        {showResults &&
          responses.map((item, idx) => (
            <TrainingCard
              key={`${item.id}-${idx}`}
              item={item}
              rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
              onButtonClick={handleGlobalApplyClick(item)}
              onLikeClick={handleGlobalLikeClick(item)}
              onDislikeClick={handleGlobalDislikeClick(item)}
            />
          ))}
        {showNoResults &&
          defaultData.pages[0]?.trainings
            .slice(0, 5)
            .map((item, idx) => (
              <TrainingCard
                key={`${item.id}-${idx}`}
                item={item}
                rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                onButtonClick={handleGlobalApplyClick(item)}
                onLikeClick={handleGlobalLikeClick(item)}
                onDislikeClick={handleGlobalDislikeClick(item)}
              />
            ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} py={4}>
        {!(filtersActive && responses.length === 0) && hasNextPage && !loadingLanguage && isFetched && isFetching ? (
          <CircularProgress />
        ) : (
          <span />
        )}
      </Box>
      <Drawer anchor="right" open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        {FiltersList}
      </Drawer>
    </Container>
  );
};

Training.propTypes = {
  width: PropTypes.string,
};

export default Training;

function capFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
