import { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

/* i18n Dependencies */
import { useIntl } from 'react-intl';

/* Assets */
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Utils */
import { debounce } from 'lib/performance';

const styles = {
  backToTop: css(
    {
      fontSize: '12px',
      lineHeight: '18px',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 0,
      svg: {
        color: 'white',
      },
      '&:hover': {
        svg: {
          color: cssVars.lightGray,
        },
      },
    },
    { label: 'back-to-top' }
  ),
  rocket: css({
    color: 'white',
    paddingRight: 8,
  }),
};

const ScrollToTopRound = () => {
  const [showGoTop, setshowGoTop] = useState(false);
  const location = useLocation();
  const intl = useIntl();

  const scrollTop = (e) => {
    e.preventDefault();
    const currentPath = location.pathname;

    if (currentPath.indexOf('careers') > -1) {
      trackEvent('CAREERS_TOP');
    }
    if (currentPath.indexOf('jobs') > -1) {
      trackEvent('JOBS_TOP');
    }

    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleVisibleButton = () => {
    const position = window.scrollY;
    if (position > 400) {
      return setshowGoTop(true);
    } else if (position <= 400) {
      return setshowGoTop(false);
    }
  };
  const debouncedHandleVisibleButton = debounce(() => handleVisibleButton(), 400);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleVisibleButton);
  });

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 50,
        right: 26,
        opacity: showGoTop ? 1 : 0,
        pointerEvents: showGoTop ? 'all' : 'none',
        transition: 'all 0.5s',
      }}
    >
      <IconButton
        onClick={scrollTop}
        className={styles.backToTop}
        sx={{ backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' }, boxShadow: 5 }}
        title={intl.formatMessage({ id: 'recommendations.scrollTop' })}
      >
        <KeyboardArrowUpIcon sx={{ fontSize: 24 }} />
      </IconButton>
    </Box>
  );
};

export default ScrollToTopRound;

export const ScrollToTopOnPathChange = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});
