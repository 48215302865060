import { createSlice } from '@reduxjs/toolkit';

export const trainingSlice = createSlice({
  name: 'training',
  initialState: {
    results: [],
    filterByNameValue: '',
    careerPathsFilterValues: [],
    skillsFilter: [],
    providersFilter: [],
    durationFilterValues: [],
    locationsFilterValues: [],
    preReqsFilterValues: [],
    sortBy: '',
    initialCareersFilterSet: false,
    showRecommendedTrainings: true,
    filterOptions: {
      locations: [],
      careerPaths: [],
      providers: [],
      skills: [],
    },
  },
  reducers: {
    setNameFilterValue: (state, action) => {
      state.filterByNameValue = action.payload;
    },
    setCareerPathsFilterValues: (state, action) => {
      state.careerPathsFilterValues = action.payload;
    },
    setSkillsFilter: (state, action) => {
      state.skillsFilter = action.payload;
    },
    setProvidersFilter: (state, action) => {
      state.providersFilter = action.payload;
    },
    setDurationFilterValues: (state, action) => {
      state.durationFilterValues = action.payload;
    },
    setLocationsFilterValues: (state, action) => {
      state.locationsFilterValues = action.payload;
    },
    setPreReqsFilterValues: (state, action) => {
      state.preReqsFilterValues = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setTraining: (state, action) => {
      state.results = action.payload;
    },
    setInitialCareersFilterSet: (state, action) => {
      state.initialCareersFilterSet = action.payload;
    },
    setShowRecommendedTrainings: (state, action) => {
      state.showRecommendedTrainings = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNameFilterValue,
  setCareerPathsFilterValues,
  setSkillsFilter,
  setProvidersFilter,
  setDurationFilterValues,
  setLocationsFilterValues,
  setPreReqsFilterValues,
  setFilterOptions,
  setSortBy,
  setTraining,
  setInitialCareersFilterSet,
  setShowRecommendedTrainings,
} = trainingSlice.actions;

export default trainingSlice.reducer;
