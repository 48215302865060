import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';

import { css } from '@emotion/css';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const ExperienceWalletCustomAutocomplete = withStyles({
  tag: {
    '& .MuiChip-label': {
      color: cssVars.white,
      fontWeight: 700,
      fontSize: '10px',
      textTransform: 'uppercase',
    },
    '& .MuiChip-deleteIcon': {
      width: '14px',
      height: '14px',
      color: 'rgb(250 250 250 / 40%) !important',
      '&:hover': {
        color: 'rgb(250 250 250 / 60%) !important',
      },
    },
  },
  endAdornment: {
    top: 'initial',
    bottom: '9px',
  },
  input: css({
    flex: '0 1 100%',
  }),
  paper: css({
    border: `solid 1px ${cssVars.mediumGray}`,
    margin: '0 !important',
    borderRadius: '0 !important',
  }),
})(Autocomplete);

export default ExperienceWalletCustomAutocomplete;
