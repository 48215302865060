import cssVars from 'styles/vars-ar.module.scss';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Divider, Typography, Box, Container, Stack } from '@mui/material';
import MetaContent from 'components/layout/MetaContent';
import CloseTabButton from 'components/CloseTabButton';

function About() {
  const intl = useIntl();
  return (
    <>
      <MetaContent />
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', mb: 7 }}>
        <Box sx={{ maxWidth: 714 }}>
          <Box sx={{ py: 4, px: 5 }}>
            <Typography align="center" variant="h1" component="h1" gutterBottom>
              <FormattedMessage id="about.title" />
            </Typography>
          </Box>
          <Divider />
          <Stack pt={4} pb={5}>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 }, fontWeight: 700, mb: 2, mt: 2 }}>
              <FormattedMessage id="about.introTitle" />
            </Typography>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 } }}>
              <FormattedMessage id="about.about" />
            </Typography>
            <Box m={1} />
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 } }}>
              <FormattedMessage id="about.about2" />
            </Typography>
            <Box m={1} />
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 }, fontWeight: 700, mb: 2, mt: 1 }}>
              <FormattedMessage id="about.features" />
            </Typography>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 } }}>
              <FormattedMessage id="about.about3" />
            </Typography>
            <Box sx={{ px: { xs: 2, md: 4 } }}>
              <ul>
                <li>
                  <FormattedMessage id="about.about3.item1" />
                </li>
                <li>
                  <FormattedMessage id="about.about3.item2" />
                </li>
                <li>
                  <FormattedMessage id="about.about3.item3" />
                </li>
                <li>
                  <FormattedMessage id="about.about3.item4" />
                </li>
              </ul>
            </Box>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 }, fontWeight: 700, mb: 2, mt: 2 }}>
              <FormattedMessage id="about.historyTitle" />
            </Typography>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 } }}>
              <FormattedMessage id="about.historyText" />
            </Typography>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 }, fontWeight: 700, mb: 2, mt: 2 }}>
              <FormattedMessage id="about.contactTitle" />
            </Typography>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 } }}>
              {intl.formatMessage({ id: 'about.about4' })}
            </Typography>
            <Typography variant="body1" component="p" sx={{ px: { xs: 2, md: 4 } }}>
              {intl.formatMessage(
                { id: 'about.about5' },
                {
                  email: (
                    <Typography
                      component="a"
                      sx={{ color: '#2d7ca9', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                      href={`mailto:${cssVars.footerEmail.slice(1, -1)}`}
                    >
                      {cssVars.footerEmail.slice(1, -1)}
                    </Typography>
                  ),
                }
              )}
            </Typography>
          </Stack>
          <Box component="footer" align="center" pb={3}>
            <CloseTabButton />
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default About;
