import { useState, useEffect, Fragment } from 'react';
import ExperienceWalletForm from 'components/forms/experienceWallet/ExperienceWalletForm';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Assets */
import info from 'assets/images/Info_Cards.svg';

/* API */
import { useQuery } from '@tanstack/react-query';
import { apiRequest } from 'lib/API';

/* Assets */
import qr from 'assets/images/doors-inspection-checked-qrcode.png';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import { Container, Typography, Box, Button, Dialog, Hidden, Alert } from '@mui/material';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { CustomNotification } from 'shared/components';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setOpenContactInfo } from 'app/slices/experienceSlice';

/* UI Components */
import { LoadingSmall } from 'components/Loading';
import MobilePaginatedDrawers from './forms/experienceWallet/MobilePaginatedDrawers';
import AddNewExperienceMenu from './forms/experienceWallet/AddNewExperienceMenu';
import ExperienceWalletDesktopTable from './forms/experienceWallet/ExperienceWalletDesktopTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QrCodeIcon from '@mui/icons-material/QrCode';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const emptyExperience = {
  type: '',
  field: null,
  skills: [],
  description: '',
  employer_provider: '',
  start_date: null,
  end_date: null,
};

const sortByMostRecent = (a, b) => {
  const dateA = new Date(a.end_date);
  const dateB = new Date(b.end_date);

  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  } else {
    return 0;
  }
};
const sortByMostOldest = (a, b) => {
  const dateA = new Date(b.end_date);
  const dateB = new Date(a.end_date);

  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  } else {
    return 0;
  }
};
const sortBySkillsAsc = (a, b) => (a.skills?.length || 0) - (b.skills?.length || 0);
const sortBySkillsDesc = (a, b) => (b.skills?.length || 0) - (a.skills?.length || 0);
const sortByFieldAsc = (a, b) => a.field?.title.localeCompare(b.field?.title);
const sortByFieldDesc = (a, b) => b.field?.title.localeCompare(a.field?.title);
const genericSort = (key, direction) => (a, b) => {
  if (!a[key] && !b[key]) {
    return 0;
  }
  if (direction === 'asc') {
    if (a[key] && !b[key]) {
      return -1;
    }
    if (b[key] && !a[key]) {
      return 1;
    }
  }
  if (direction === 'desc') {
    if (b[key] && !a[key]) {
      return -1;
    }
    if (a[key] && !b[key]) {
      return 1;
    }
  }

  if (direction === 'asc') {
    return a[key].localeCompare(b[key]);
  }
  return b[key].localeCompare(a[key]);
};

// TODO: Remove this comment. Trying to force re-dceploy

const Experience = () => {
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { enqueueSnackbar } = useSnackbar();
  const user_settings = useSelector((state) => state.app.user_settings);

  const [experience, setExperience] = useState([]);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [sortBy, setSortBy] = useState('default');
  const [sortDirection, setSortDirection] = useState('desc');
  const [newExperienceOpen, setNewExperienceOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [itemToEdit, setItemToEdit] = useState(-1);
  const dispatch = useDispatch();

  /*-- initial load --*/
  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnMount: 'always',
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  const contactQuery = useQuery({
    queryKey: ['/users/me'],
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!experienceQuery.isFetching && experienceQuery.isSuccess) {
      setExperience(
        experienceQuery.data?.job_experience
          ?.map((exp) => ({
            ...exp,
            field: exp.fields[0],
            id: uuidv4(),
          }))
          .sort(sortByMostRecent)
      );
    }
  }, [experienceQuery.isSuccess, experienceQuery.isFetching]);

  useEffect(() => {
    let intervalId;
    if (refetchCounter > 0) {
      intervalId = setInterval(function () {
        apiRequest('get', '/users/me/experience/').then((response) => {
          const currentCount = experienceQuery.data?.job_experience?.reduce((prev, curr) => {
            if (curr.wallet) {
              return prev + 1;
            }
            return prev;
          }, 0);
          const newCount = response?.job_experience?.reduce((prev, curr) => {
            if (curr.wallet) {
              return prev + 1;
            }
            return prev;
          }, 0);
          if (newCount > currentCount) {
            clearInterval(intervalId);
            setExperience(
              response?.job_experience?.map((exp) => ({
                ...exp,
                field: exp.fields[0],
                id: uuidv4(),
              }))
            );
            enqueueSnackbar('', {
              variant: 'default',
              content: (key) => (
                <CustomNotification id={key}>
                  {intl.formatMessage({ id: 'experience.notification.importSuccess' })}
                </CustomNotification>
              ),
            });
          }
        });
      }, 10000);
    }
  }, [refetchCounter]);

  const replaceItem = (index, item) => {
    const newExperience = experience.concat();
    newExperience[index] = { ...item };
    setExperience(newExperience);
  };

  const deleteItem = (index) => {
    setItemToDelete(index);
    setDeleteDialogOpen(true);
  };

  const appendItem = (item) => {
    setExperience(experience.concat({ id: uuidv4(), ...item }));
  };

  /*-- analytics --*/
  useEffect(() => {
    document.title = intl.formatMessage({ id: 'experience.page.altTitle' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('SKILLS');
    TimeMe.startTimer();
    return () => {
      dispatch(setOpenContactInfo(false));
    };
  }, []);

  useEffect(() => {
    const sortedExperience =
      sortBy === 'experience'
        ? experience.slice().sort(genericSort('type', sortDirection))
        : sortBy === 'description'
        ? experience.slice().sort(genericSort('description', sortDirection))
        : sortBy === 'default'
        ? experience.slice().sort(sortDirection === 'asc' ? sortByMostOldest : sortByMostRecent)
        : sortBy === 'field'
        ? experience.slice().sort(sortDirection === 'asc' ? sortByFieldAsc : sortByFieldDesc)
        : sortBy === 'numberSkills'
        ? experience.slice().sort(sortDirection === 'asc' ? sortBySkillsAsc : sortBySkillsDesc)
        : sortBy === 'provider'
        ? experience.slice().sort(genericSort('employer_provider', sortDirection))
        : experience;

    setExperience(sortedExperience);
  }, [sortBy, sortDirection, experience.length]);

  const matchFeatureValue =
    !contactQuery.isLoading && !contactQuery.isFetching
      ? pathOr(false, ['data', 'user_match_feature'], contactQuery)
      : false;

  return (
    <div className={recStyles.content}>
      <Container maxWidth="lg">
        <Box>
          <Typography variant="h1">{intl.formatMessage({ id: 'experience.page.altTitle' })}</Typography>
          {/* Temporarily hiding the message about LFE opt-in */}
          {false && !experienceQuery.isLoading && !contactQuery.isLoading && (
            <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
              <Typography variant="body2">{intl.formatMessage({ id: 'experience.page.altSubTitle' })}</Typography>
              <Typography variant="body2" sx={{ pt: 1, a: { color: 'primary.dark', fontWeight: 500 } }}>
                {intl.formatMessage(
                  { id: 'experience.page.altSubTitle2' },
                  {
                    privacyMatters: <b>{intl.formatMessage({ id: 'experience.page.privacyMatters' })}</b>,
                    matchStatus: (
                      <b>{intl.formatMessage({ id: `profile.matchFeature.${matchFeatureValue ? 'on' : 'off'}` })}</b>
                    ),
                    link: <Link to={`${urlPrefix}/my-profile`}>{intl.formatMessage({ id: 'profile.title' })}</Link>,
                  }
                )}
              </Typography>
            </Alert>
          )}

          {user_settings.ler_wallet_credentials_available && false ? (
            <Typography
              component="a"
              href="https://www.youtube.com/watch?v=N_o3TKsyvkI"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: 1,
                color: (theme) => theme.palette.primary.main,
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
            >
              {intl.formatMessage({ id: 'experience.page.walletExplanations' })}{' '}
              <OpenInNewIcon sx={{ ml: 0.5, fontSize: 16, position: 'relative', top: 4 }} />
            </Typography>
          ) : null}
        </Box>
        <Hidden smUp>
          {user_settings.ler_wallet_credentials_available ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <AddNewExperienceMenu
                addNewExperience={() => setNewExperienceOpen(true)}
                clickAddFromWallet={() => setRefetchCounter(refetchCounter + 1)}
              />
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                title={
                  <Fragment>
                    <span style={{ fontSize: 14 }}>{intl.formatMessage({ id: 'experience.mobileTooltip' })}</span>
                  </Fragment>
                }
                placement="top"
                arrow
              >
                <img
                  alt="Info"
                  src={info}
                  style={{
                    position: 'relative',
                    top: 1,
                    paddingLeft: 16,
                    width: '17px',
                    height: '17px',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Button
              color="primary"
              size="large"
              variant="contained"
              startIcon={<AddCircleIcon sx={{ position: 'relative', bottom: 1 }} />}
              sx={{ textTransform: 'none', fontSize: 14, mt: 2 }}
              onClick={() => setNewExperienceOpen(true)}
            >
              {intl.formatMessage({ id: 'experience.page.addNewExperience' })}
            </Button>
          )}
        </Hidden>
        <Box
          sx={{
            mt: 1,
            display: { xs: 'none', sm: experienceQuery.isLoading || contactQuery.isLoading ? 'none' : 'flex' },
          }}
        >
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<AddCircleIcon sx={{ position: 'relative', bottom: 1 }} />}
            sx={{ mr: 2, textTransform: 'none', fontSize: 14 }}
            onClick={() => setNewExperienceOpen(true)}
            id="add-new-experience-button"
          >
            {intl.formatMessage({ id: 'experience.page.addNewExperience' })}
          </Button>
          {/* Desktop button */}
          {user_settings.ler_wallet_credentials_available && false ? (
            <Button
              color="primary"
              size="large"
              variant="contained"
              startIcon={<QrCodeIcon sx={{ position: 'relative', bottom: 1 }} />}
              sx={{ textTransform: 'none', fontSize: 14 }}
              onClick={() => {
                setQrDialogOpen(true);
                trackEvent('EXPERIENCE_WALLET_ADD_BUTTON_CLICK');
              }}
            >
              {intl.formatMessage({ id: 'experience.page.shareFromWallet' })}
            </Button>
          ) : null}
        </Box>
        <Box sx={{ mb: 4 }} /> {/* TODO: Remove when enabling wallet message */}
        {user_settings.ler_wallet_credentials_available && false ? (
          <Box
            sx={{ display: { xs: 'none', sm: experienceQuery.isLoading || contactQuery.isLoading ? 'none' : 'flex' } }}
          >
            <Typography
              component="a"
              href="https://www.youtube.com/watch?v=N_o3TKsyvkI"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: (theme) => theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
            >
              {intl.formatMessage({ id: 'experience.page.walletExplanations' })}{' '}
              <OpenInNewIcon sx={{ ml: 0.5, fontSize: 16 }} />
            </Typography>
          </Box>
        ) : null}
        {experienceQuery.isLoading || contactQuery.isLoading ? (
          <LoadingSmall />
        ) : (
          <div>
            <Box sx={{ margin: { xs: 2, sm: 3 } }} />
            <Hidden mdUp>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '209px',
                }}
              >
                <FormControl variant="filled" sx={{ mb: 2, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    {intl.formatMessage({ id: 'experience.sort.sortBy' })}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={sortBy}
                    onChange={(evt) => {
                      setSortBy(evt.target.value);
                      if (evt.target.value === 'numberSkills' || evt.target.value === 'default') {
                        setSortDirection('desc');
                      } else {
                        setSortDirection('asc');
                      }
                    }}
                  >
                    <MenuItem value="default">{intl.formatMessage({ id: 'experience.sort.default' })}</MenuItem>
                    <MenuItem value="experience">{intl.formatMessage({ id: 'experience.sort.experience' })}</MenuItem>
                    <MenuItem value="description">{intl.formatMessage({ id: 'experience.sort.description' })}</MenuItem>
                    <MenuItem value="numberSkills">
                      {intl.formatMessage({ id: 'experience.sort.numberSkills' })}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {experience.length > 0 ? (
                <MobilePaginatedDrawers replaceItem={replaceItem} deleteItem={deleteItem} experience={experience} />
              ) : null}
            </Hidden>
            <Hidden mdDown>
              {experience.length > 0 ? (
                <ExperienceWalletDesktopTable
                  experience={experience}
                  deleteItem={deleteItem}
                  itemToEdit={itemToEdit}
                  setItemToEdit={setItemToEdit}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                />
              ) : null}
            </Hidden>
            <Box m={3} />
          </div>
        )}
      </Container>
      <Dialog open={newExperienceOpen || itemToEdit !== -1} onClose={() => {}}>
        <Box sx={{ px: 3, py: 2, maxWidth: 464, boxSizing: 'border-box' }}>
          <IconButton
            open={open}
            aria-label="Close"
            onClick={() => {
              setNewExperienceOpen(false);
              setItemToEdit(-1);
            }}
            sx={{
              position: 'absolute',
              right: 6,
              top: 6,
              color: 'black',
              fontSize: 34,
            }}
          >
            <CloseIcon />
          </IconButton>
          <ExperienceWalletForm
            appendItem={itemToEdit !== -1 ? null : appendItem}
            replaceItem={itemToEdit !== -1 ? replaceItem : null}
            currentIndex={itemToEdit}
            experience={itemToEdit !== -1 ? experience[itemToEdit] : emptyExperience}
            experiences={experience}
            setCurrentContent={() => {
              setNewExperienceOpen(false);
              setItemToEdit(-1);
            }} // In this case we use this method to close the modal
          />
        </Box>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => {}}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {intl.formatMessage({ id: 'experience.deleteDialog.title' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'experience.deleteDialog.text' })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleteDialogOpen(false)} id="delete-go-back-experience-button">
            {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.cancel' })}
          </Button>
          <Button
            id="delete-yes-experience-button"
            onClick={() => {
              if (itemToDelete !== -1) {
                const itemObject = experience[itemToDelete];
                if (itemObject.wallet) {
                  trackEvent('EXPERIENCE_WALLET_DELETE', itemObject);
                } else {
                  trackEvent('EXPERIENCE_MANUAL_DELETE', itemObject);
                }
                let newExperience = [...experience.slice(0, itemToDelete), ...experience.slice(itemToDelete + 1)];
                setExperience(newExperience);
                apiRequest('PUT', '/users/me/experience/', {
                  data: {
                    job_experience: newExperience.map((exp) => {
                      const { id, ...rest } = exp; // eslint-disable-line
                      return rest;
                    }),
                  },
                });
              }
              setDeleteDialogOpen(false);
            }}
          >
            {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.continue' })}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={qrDialogOpen}
        onClose={() => {
          setQrDialogOpen(false);
          setRefetchCounter(refetchCounter + 1);
        }}
      >
        <DialogContent>
          <Typography sx={{ maxWidth: 400, pl: 2.6, pt: 2.2 }}>
            {intl.formatMessage({ id: 'profile.qrDialog.title' })}
          </Typography>
          <Typography sx={{ maxWidth: 400, pl: 2.6, pt: 2.2, color: 'primary.dark' }}>
            <img src={info} style={{ widht: 18, height: 18, position: 'relative', left: -2, top: 3 }} />
            {intl.formatMessage(
              { id: 'profile.qrDialog.subTitle' },
              { bold: <b>{intl.formatMessage({ id: 'profile.qrDialog.boldText' })}</b> }
            )}
          </Typography>
          <img src={qr} style={{ width: 420, height: 420 }} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setQrDialogOpen(false);
              setRefetchCounter(refetchCounter + 1);
            }}
          >
            {intl.formatMessage({ id: 'layout.hamburger.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function uuidv4() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export default Experience;
