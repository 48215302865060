import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getRecommendedTrainings, updateTrainings } from 'shared/src/lib/API/trainings';

const getTrainingsQueryKey = (filters) => [{ entity: 'trainings', ...filters }];

const paginatedTrainingsQueryFn = async ({ queryKey, pageParam = 0 }) => {
  const {
    filter_by_name,
    filter_by_soc,
    filter_by_skills,
    filter_by_provider,
    filter_by_duration,
    filter_by_county,
    filter_by_prereqs,
    sort_by,
    all_trainings,
  } = queryKey[0];

  const data = await getRecommendedTrainings([], {
    limit: 9,
    offset: pageParam * 9,
    ...(filter_by_name && filter_by_name.length ? { filter_by_name } : {}),
    ...(filter_by_soc && filter_by_soc.length ? { filter_by_soc: filter_by_soc.join('|') } : {}),
    ...(filter_by_skills && filter_by_skills.length ? { filter_by_skills: filter_by_skills.join('|') } : {}),
    ...(filter_by_provider && filter_by_provider.length ? { filter_by_provider: filter_by_provider.join('|') } : {}),
    ...(filter_by_duration && filter_by_duration.length ? { filter_by_duration: filter_by_duration.join('|') } : {}),
    ...(filter_by_county && filter_by_county.length ? { filter_by_county: filter_by_county.join('|') } : {}),
    ...(filter_by_prereqs && filter_by_prereqs.length ? { filter_by_prereqs: filter_by_prereqs.join('|') } : {}),
    ...(sort_by ? { sort_by, sort_asc: true } : {}),
    ...(all_trainings ? { all_trainings } : {}),
  });

  return data;
};

export const useInfiniteTrainings = (filters) => {
  return useInfiniteQuery({
    queryFn: paginatedTrainingsQueryFn,
    retry: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.trainings?.length < 9) {
        return undefined;
      }
      return pages.flat(1).length;
    },
    queryKey: getTrainingsQueryKey(filters),
  });
};

export const useRateTraining = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = getTrainingsQueryKey(filters);

  return useMutation({
    mutationFn: ({ training, rating }) => {
      return updateTrainings(training, rating);
    },
    onMutate: async ({ training, rating }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousTrainings = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old);
        clone.pages.forEach((page, pageIndex) => {
          page.trainings?.forEach((oldTraining, oldTrainingIndex) => {
            if (oldTraining.id === training.id) {
              clone.pages[pageIndex].trainings[oldTrainingIndex].rating = rating;
            }
          });
        });

        return clone;
      });

      // Return a context object with the snapshotted value
      return { previousTrainings };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousTrainings);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
