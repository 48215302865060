import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { apiRequest } from 'lib/API';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import { css } from '@emotion/css';
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import editJobStyles from 'styles/edit-job-activity.module.scss';
import 'react-circular-progressbar/dist/styles.css';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Dialog,
  DialogContent,
  Button,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import info from 'assets/images/Info_Cards.svg';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setActivities, setStatus, setLoaded } from 'app/slices/workSearchSlice';

/* UI Components */
import EditJobActivity from 'components/EditJobActivity';
import SearchActivityCard from 'components/SearchActivityCard';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import SSNDialog from 'components/forms/SSNDialog';

import { useExportActivitySummary, useFormatDateRange } from 'shared/hooks';
import { grey } from '@mui/material/colors';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#151515',
  },
}));
const localStyles = {
  waived: css({
    color: grey['800'],
    backgroundColor: '#ebf9e9',
    border: `solid 2px #8bb98b`,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  inactive: css({
    color: grey['800'],
    backgroundColor: '#fccb0025',
    border: `solid 2px #fccb0095`,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  checkIcon: css({
    fontSize: '17px !important',
  }),
  icon: css({
    height: '19px',
    width: '24px',
    backgroundColor: '#0e6e10',
    borderRadius: '50%',
    color: 'white',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '6px',
  }),
};

function getTo(fromStr) {
  const year = fromStr.slice(0, 4);
  const month = fromStr.slice(5, 7);
  const day = fromStr.slice(8, 10);
  const from = new Date(year, month - 1, day);
  from.setDate(from.getDate() + 6);
  return from.toISOString().slice(0, 10);
}

export function getWeekArr(status, activities) {
  if (!status.week_start_dates || !status.week_start_dates.length) {
    return [];
  }
  const weeksObj = status.week_start_dates.reduce((acc, curr) => {
    acc[curr] = {
      from: curr,
      to: getTo(curr),
      required: 5,
      items: [],
    };
    return acc;
  }, {});
  activities.forEach((curr) => {
    const week = curr.week_start_date;
    weeksObj[week]?.items?.push(curr);
  });
  const weeks = Object.keys(weeksObj).sort((a, b) => {
    return new Date(b) - new Date(a);
  });
  const weekArr = weeks.map((w) => weeksObj[w]);
  return weekArr;
}

const JobSearchActivity = () => {
  const intl = useIntl();
  const tooltipClasses = useStylesBootstrap();
  const formatDateRange = useFormatDateRange();
  const isSmall = useMediaQuery('(max-width:767px)');
  const urlPrefix = getUrlPrefix(intl.locale);
  const user_settings = useSelector((state) => state.app.user_settings);
  const loaded_worksearch = useSelector((state) => state.worksearch.loaded);
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const worksearch_activities = useSelector((state) => state.worksearch.activities);

  const [editModalsOpen, setEditModalsOpen] = useState([false]);
  const [formsSelectedActivities, setFormSelectedActivities] = useStateCallback(['']);
  const [ssnDialogOpen, setSsnDialogOpen] = useState(Boolean(worksearch_status?.ssn_required));
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false);
  const [detailedActivity, setDetailedActivity] = useState([]);
  const [generalActivity, setGeneralActivity] = useState(useSelector((state) => state.worksearch.status));
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const { lang } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  if (lang === 'onboarding') {
    history.push(`${urlPrefix}/careers`);
  }

  const completedOverall =
    (generalActivity.overall_activities_completed / generalActivity.overall_activities_required) * 100;
  const completedCurrentWeek =
    (generalActivity.weekly_activities_completed / generalActivity.weekly_activities_required) * 100;

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'jobSearchActivity.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBSEARCH');
    TimeMe.startTimer();
    return () => {
      console.log('Leaving WorkSearch');
    };
  }, []);

  useEffect(() => {
    if (!loaded_worksearch) {
      setLoadingActivity(true);
      apiRequest('GET', '/users/me/worksearch/').then(({ activities, status }) => {
        const weekArr = getWeekArr(status, activities);
        setDetailedActivity(weekArr);
        dispatch(setActivities(activities));

        setGeneralActivity(status);
        dispatch(setStatus(status));

        const newEditModalsOpen = new Array(weekArr.length).fill(false);
        const newFormSelectedActivities = new Array(weekArr.length).fill('');
        setEditModalsOpen(newEditModalsOpen);
        setFormSelectedActivities(newFormSelectedActivities);

        setSsnDialogOpen(status.ssn_required);
        if (!status.ssn_required) {
          dispatch(setLoaded(true));
        }
        setLoadingActivity(false);
      });
    } else {
      const weekArr = getWeekArr(worksearch_status, worksearch_activities);
      setDetailedActivity(weekArr);
      setGeneralActivity(worksearch_status);

      const newEditModalsOpen = new Array(weekArr.length).fill(false);
      const newFormSelectedActivities = new Array(weekArr.length).fill('');
      setEditModalsOpen(newEditModalsOpen);
      setFormSelectedActivities(newFormSelectedActivities);
    }
  }, [refreshCounter, loaded_worksearch, worksearch_activities.length]);

  const addActivityItem = (item) => {
    let found = false;
    const newDetailedActivity = detailedActivity.map((w) => {
      if (w.from === item.week_start_date) {
        w.items.push(item);
        found = true;
      }
      w.items.sort((a, b) => {
        return new Date(a.activity_date) - new Date(b.activity_date);
      });
      return w;
    });
    if (!found) {
      newDetailedActivity.push({
        from: item.week_start_date,
        to: getTo(item.week_start_date),
        required: 3,
        items: [item],
      });
    }
    newDetailedActivity.sort((a, b) => {
      return new Date(b.from) - new Date(a.from);
    });
    const newEditModalsOpen = new Array(newDetailedActivity.length).fill(false);
    const newFormSelectedActivities = new Array(newDetailedActivity.length).fill('');
    setDetailedActivity(newDetailedActivity);
    setEditModalsOpen(newEditModalsOpen);
    setFormSelectedActivities(newFormSelectedActivities);
    dispatch(setActivities(worksearch_activities.concat(item)));
  };
  const updateActivityItem = (item) => {
    const newDetailedActivity = detailedActivity.map((w) => {
      if (w.from === item.week_start_date) {
        let found = false;
        w.items = w.items.map((i) => {
          if (i.worksearch_id === item.worksearch_id) {
            found = true;
            return item;
          }
          return i;
        });
        if (!found) {
          w.items.push(item);
        }
      } else {
        w.items = w.items.filter((i) => {
          return i.worksearch_id !== item.worksearch_id;
        });
      }
      w.items.sort((a, b) => {
        return new Date(a.activity_date) - new Date(b.activity_date);
      });
      return w;
    });
    setDetailedActivity(newDetailedActivity);
    const newWorksearchActivities = worksearch_activities.map((i) => {
      if (i.worksearch_id === item.worksearch_id) {
        return item;
      }
      return i;
    });
    dispatch(setActivities(newWorksearchActivities));
  };

  function activityString(from, to) {
    return `${intl.formatMessage({ id: 'jobSearchActivity.activityFor' })} ${formatDateRange(from, to)}`;
  }
  function formatDate(date) {
    const year = date.substr(2, 2);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    return `${parseInt(month, 10)}.${parseInt(day, 10)}.${year}`;
  }
  const activitiesDict = {
    applied_for_job: intl.formatMessage({ id: 'jobSearchActivity.applyForAJob' }),
    participate_in_resea: intl.formatMessage({ id: 'jobSearchActivity.activity.participatedReseaWorkshop' }),
    attend_job_fair: intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' }),
    attend_job_seminar: intl.formatMessage({ id: 'jobSearchActivity.attendJobSearch' }),
    job_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.scheduleMeeting' }),
    interview_with_employer: intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' }),
    follow_up_with_employer: intl.formatMessage({ id: 'jobSearchActivity.directContact' }),
    register_with_school: intl.formatMessage({ id: 'jobSearchActivity.registerFacility' }),
    resume_upload: intl.formatMessage({ id: 'jobSearchActivity.activity.uploadedResume' }),
    draft: intl.formatMessage({ id: 'jobSearchActivity.draft' }),
  };
  const fieldsDict = {
    company_name: intl.formatMessage({ id: 'jobSearchActivity.form.companyName' }),
    contact_name: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
    contact_email: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
    contact_phone_number: intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' }),
    position_applied_for: intl.formatMessage({ id: 'jobSearchActivity.form.positionApplied' }),
    notes: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
    method_of_contact: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfContact' }),
    application_filed: intl.formatMessage({ id: 'jobSearchActivity.form.applicationFiled' }),
    name_of_school: intl.formatMessage({ id: 'jobSearchActivity.form.nameOfSchoolPlacement' }),
    result_of_contact: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfContact' }),
    result_of_seminar: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfSeminar' }),
    result_of_interview: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfInterview' }),
    result_of_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfCoachMeeting' }),
    method_of_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfCoachMeeting' }),
    method_of_interview: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfInterview' }),
    type_of_seminar: intl.formatMessage({ id: 'jobSearchActivity.form.typeOfSeminar' }),
    type_of_activity: intl.formatMessage({ id: 'jobSearchActivity.form.typeOfActivity' }),
    attended_skills_assessment: intl.formatMessage({ id: 'jobSearchActivity.form.attendedSkills' }),
    application_submission_format: intl.formatMessage({ id: 'jobSearchActivity.form.applyJob.asf' }),
    in_person: intl.formatMessage({ id: 'jobSearchActivity.form.inPerson' }),
    performed_interview: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption3' }),
    resumes_submitted: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption1' }),
    interviews_scheduled: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption2' }),
    result_of_job_fair: intl.formatMessage({ id: 'jobSearchActivity.fair.ResultOfJobFair' }),
    job_fair: intl.formatMessage({ id: 'jobSearchActivity.fair.jobFair' }),
    job_fair_website: intl.formatMessage({ id: 'jobSearchActivity.fair.jobFairWebsite' }),
    location: intl.formatMessage({ id: 'jobSearchActivity.fair.locationOfJobFair' }),
    time_of_job_fair: intl.formatMessage({ id: 'jobSearchActivity.fair.timeOfJobFair' }),
    interviewer_name: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerName' }),
    interviewer_phone_number: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerPhone' }),
    interviewer_email: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerEmail' }),
    position_interviewed_for: intl.formatMessage({ id: 'jobSearchActivity.form.positionInterviewedFor' }),
  };

  const exportActivitySummary = useExportActivitySummary(fieldsDict, activitiesDict);
  const handlePrintHistory = () => {
    setLoadingDialogOpen(true);
    trackEvent('ACTIVITY_SHARE_DOWNLOAD');

    apiRequest('GET', '/users/me/worksearch/').then(({ activities }) => {
      exportActivitySummary(
        intl.formatMessage({ id: 'jobSearchActivity.activitySummary' }),
        '/images/AR-Launch-Logo-Green-Small.png',
        activities,
        26.48,
        12,
        'launch.arkansas.gov'
      );
      setLoadingDialogOpen(false);
    });
  };

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          {user_settings.job_search_activity_header ? (
            <>
              <div className={styles.titleFilters}>
                <Typography variant="h1" component="h2">
                  {intl.formatMessage({ id: 'jobSearchActivity.title' })}
                </Typography>
                <Box pb={!isSmall ? 5 : 1} />
              </div>
              <hr className={layoutStyles.separator} />
            </>
          ) : null}
          {loadingActivity ? (
            <Box m={7} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {user_settings.job_search_activity_summary_print_or_share ? <Box pb={1} /> : null}
              {user_settings.job_search_activity_summary_print_or_share ? (
                <div className={styles.printShareToolbar}>
                  <div />
                  <div className={styles.printShareToolbar__right}>
                    {user_settings.job_search_activity_summary_print_link ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handlePrintHistory}
                        style={{ borderRadius: 4 }}
                      >
                        {intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                        <PdfIcon
                          style={{ fontSize: 18, marginLeft: 4, position: 'relative', bottom: 1 }}
                          alt={intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                          name={intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                        />
                      </Button>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {user_settings.job_search_activity_summary ? (
                <div className={styles.jobSearchActivityMain}>
                  <p>{intl.formatMessage({ id: 'jobSearchActivity.explanationText' })}</p>
                  <div className={styles.jobSearchActivityMainInner}>
                    <div className={cn(styles.jobSearchActivityMain__half, styles.firstHalf)}>
                      <Typography variant="h5" component="h2">
                        {intl.formatMessage({ id: 'jobSearchActivity.youreRequired' })}
                      </Typography>
                      <ul className={styles.jobSearchActivityMain__list}>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <Link
                              to={`${urlPrefix}/jobs`}
                              className={layoutStyles.link}
                              onClick={() => {
                                trackEvent('ACTIVITY_LINK_APPLYJOB');
                              }}
                            >
                              {intl.formatMessage({ id: 'jobSearchActivity.applyForJob' })}
                            </Link>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' })}</span>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' })}</span>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.participateInWorkshop' })}</span>
                          </Typography>
                        </li>
                      </ul>
                    </div>
                    <div className={cn(styles.jobSearchActivityMain__half, styles.jobSearchActivityMain__cards)}>
                      {user_settings.job_search_activity_required_weekly_activity_chart ? (
                        <>
                          <div className={styles.requiredActivityCard}>
                            <div className={styles.requiredActivityCard__text}>
                              <h3 className={styles.requiredActivityCard__title}>
                                {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                                <br />
                                {formatDateRange(
                                  generalActivity.weekly_activity_start_date,
                                  generalActivity.weekly_activity_end_date
                                )}
                              </h3>
                              <span className={styles.requiredActivityCard__description}>
                                {intl.formatMessage(
                                  { id: 'jobSearchActivity.completedTasks' },
                                  {
                                    completed: generalActivity.weekly_activities_completed,
                                    total: generalActivity.weekly_activities_required,
                                  }
                                )}
                              </span>
                            </div>
                            <div className={styles.requiredActivityCard__circle}>
                              <CircularProgressbarWithChildren
                                value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                                strokeWidth={18}
                                styles={buildStyles({
                                  strokeLinecap: 'butt',
                                  pathColor: completedCurrentWeek >= 100 ? '#0E6E10' : '#FCCB00',
                                  backgroundColor: '#B7B7B7',
                                })}
                              >
                                <span className={styles.circleCompleted}>
                                  {generalActivity.weekly_activities_completed}
                                </span>
                                <span className={styles.circleTotal}>
                                  {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                                  {generalActivity.weekly_activities_required}
                                </span>
                              </CircularProgressbarWithChildren>
                            </div>
                          </div>
                          <div className={styles.requiredActivityCardMobile}>
                            <h3 className={cn(styles.requiredActivityCard__title, styles.requiredActivityTitleMobile)}>
                              {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                              <br />
                              {formatDateRange(
                                generalActivity.weekly_activity_start_date,
                                generalActivity.weekly_activity_end_date
                              )}
                            </h3>
                            <div className={styles.circleCentered}>
                              <div className={styles.requiredActivityCard__circle}>
                                <CircularProgressbarWithChildren
                                  value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                                  strokeWidth={18}
                                  styles={buildStyles({
                                    strokeLinecap: 'butt',
                                    pathColor: completedCurrentWeek >= 100 ? '#0E6E10' : '#FCCB00',
                                    backgroundColor: '#B7B7B7',
                                  })}
                                >
                                  <span className={styles.circleCompleted}>
                                    {generalActivity.weekly_activities_completed}
                                  </span>
                                  <span className={styles.circleTotal}>
                                    {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                                    {generalActivity.weekly_activities_required}
                                  </span>
                                </CircularProgressbarWithChildren>
                              </div>
                            </div>
                            <span className={cn(styles.requiredActivityCard__description, styles.descriptionMobile)}>
                              {intl.formatMessage(
                                { id: 'jobSearchActivity.completedTasks' },
                                {
                                  completed: generalActivity.weekly_activities_completed,
                                  total: generalActivity.weekly_activities_required,
                                }
                              )}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {false && user_settings.job_search_activity_required_overall_activity_chart ? ( //eslint-disable-line
                        <div className={styles.requiredActivityCard}>
                          <div className={styles.requiredActivityCard__text}>
                            <h3 className={styles.requiredActivityCard__title}>
                              {intl.formatMessage({ id: 'jobSearchActivity.requiredOverall' })}
                              <br />
                              {formatDateRange(
                                generalActivity.overall_activity_start_date,
                                generalActivity.overall_activity_end_date
                              )}
                            </h3>
                            <span className={styles.requiredActivityCard__description}>
                              {intl.formatMessage(
                                { id: 'jobSearchActivity.completedTasks' },
                                {
                                  completed: generalActivity.overall_activities_completed,
                                  total: generalActivity.overall_activities_required,
                                }
                              )}
                            </span>
                          </div>
                          <div className={styles.requiredActivityCard__circle}>
                            <CircularProgressbarWithChildren
                              value={completedOverall > 100 ? 100 : completedOverall}
                              strokeWidth={18}
                              styles={buildStyles({
                                strokeLinecap: 'butt',
                                pathColor: completedOverall >= 100 ? '#0E6E10' : '#FCCB00',
                                backgroundColor: '#B7B7B7',
                              })}
                            >
                              <span className={styles.circleCompleted}>
                                {generalActivity.overall_activities_completed}
                              </span>
                              <span className={styles.circleTotal}>
                                {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                                {generalActivity.overall_activities_required}
                              </span>
                            </CircularProgressbarWithChildren>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {worksearch_status.overall_activities_required === 0 &&
              !worksearch_status.is_inactive &&
              !ssnDialogOpen &&
              !loadingActivity ? (
                <Box mt={3} className={localStyles.waived}>
                  <span className={localStyles.icon}>
                    <CheckIcon className={localStyles.checkIcon} />
                  </span>
                  {intl.formatMessage({ id: 'jobSearchActivity.waived' })}
                </Box>
              ) : null}
              {!worksearch_status.ssn_required &&
              !worksearch_status.worksearch_enabled &&
              !ssnDialogOpen &&
              !loadingActivity ? (
                <Box mt={3} className={localStyles.waived}>
                  <span className={localStyles.icon}>
                    <CheckIcon className={localStyles.checkIcon} />
                  </span>
                  {intl.formatMessage({ id: 'jobSearchActivity.ssnNotFound' })}
                </Box>
              ) : null}

              {worksearch_status.is_inactive ? (
                <Box mt={3} className={localStyles.inactive}>
                  <InfoIcon sx={{ position: 'relative', right: { xs: 5, md: 8 }, color: '#796204d4' }} />
                  {intl.formatMessage({ id: 'jobSearchActivity.inactive' })}
                </Box>
              ) : null}
              {user_settings.job_search_activity_summary ? <Box mb={3} /> : null}
              {user_settings.job_search_activity_weekly_activity
                ? detailedActivity.map((week, idx) => (
                    <Fragment key={`${week.from}${week.to}`}>
                      {user_settings.job_search_activity_summary || idx !== 0 ? (
                        <hr className={layoutStyles.separator} />
                      ) : null}
                      <Box mb={5} />
                      <Typography variant="h4" component="h3" style={{ display: 'flex' }}>
                        {activityString(week.from, week.to)}{' '}
                        <Tooltip
                          enterTouchDelay={0}
                          title={
                            <Fragment>
                              <span style={{ fontSize: 14 }}>
                                {intl.formatMessage({ id: 'jobSearchActivity.activity.weekTooltip' })}
                              </span>
                            </Fragment>
                          }
                          placement="top"
                          arrow
                          classes={tooltipClasses}
                        >
                          <Box ml={1}>
                            <img
                              alt="Info"
                              src={info}
                              style={{
                                width: '15px',
                                height: '15px',
                                cursor: 'pointer',
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body2">
                        {intl.formatMessage(
                          { id: 'jobSearchActivity.tasksCompleted' },
                          { completed: week.items.filter((it) => !it.draft).length, total: week.required }
                        )}
                      </Typography>
                      <div
                        className={cn({
                          [editJobStyles.editJobContainer]: true,
                          [editJobStyles.editJobContainerOpaque]: editModalsOpen[idx],
                        })}
                      >
                        <EditJobActivity
                          week={week}
                          editModalOpen={editModalsOpen[idx]}
                          setEditModalOpen={(open) => {
                            setEditModalsOpen(
                              editModalsOpen
                                .slice(0, idx)
                                .concat([open])
                                .concat(editModalsOpen.slice(idx + 1))
                            );
                          }}
                          addActivityItem={addActivityItem}
                          updateActivityItem={updateActivityItem}
                          setGeneralActivity={(newStatus) => {
                            setGeneralActivity(newStatus);
                            dispatch(setStatus(newStatus));
                          }}
                          selectedActivity={{ ...formsSelectedActivities[idx] }}
                        />
                        <div
                          className={cn({
                            [editJobStyles.cardsContainer]: true,
                            [editJobStyles.absolute]: editModalsOpen[idx],
                          })}
                        >
                          {week.items.map((item) => (
                            <SearchActivityCard
                              key={item.worksearch_id}
                              type={item.state}
                              item={item}
                              date={formatDate(item.activity_date)}
                              notClickable={item.activity_type === 'resume_upload'}
                              onClick={() => {
                                if (item.activity_type === 'resume_upload') {
                                  return;
                                }
                                trackEvent('WEEK_VIEWACTIVITY_COMPLETE');
                                setFormSelectedActivities(
                                  formsSelectedActivities
                                    .slice(0, idx)
                                    .concat([item])
                                    .concat(formsSelectedActivities.slice(idx + 1)),
                                  () =>
                                    setEditModalsOpen(
                                      editModalsOpen
                                        .slice(0, idx)
                                        .concat([true])
                                        .concat(editModalsOpen.slice(idx + 1))
                                    )
                                );
                              }}
                              refreshCounter={refreshCounter}
                              setRefreshCounter={setRefreshCounter}
                            />
                          ))}
                          {week.items.length < week.required ? (
                            <SearchActivityCard
                              type="ADD_NEW"
                              title={`[${intl.formatMessage({ id: 'jobSearchActivity.addContactInformation' })}]`}
                              onClick={() => {
                                trackEvent('WEEK_ADDACTIVITY_NEW');
                                setFormSelectedActivities(
                                  formsSelectedActivities
                                    .slice(0, idx)
                                    .concat([false])
                                    .concat(formsSelectedActivities.slice(idx + 1)),
                                  () =>
                                    setEditModalsOpen(
                                      editModalsOpen
                                        .slice(0, idx)
                                        .concat([true])
                                        .concat(editModalsOpen.slice(idx + 1))
                                    )
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <Box m={6} />
                    </Fragment>
                  ))
                : null}
            </>
          )}
        </Container>
      </div>
      <LoadingDialog open={loadingDialogOpen} />
      <SSNDialog
        open={ssnDialogOpen && !loadingActivity}
        setOpen={setSsnDialogOpen}
        refreshCounter={refreshCounter}
        setRefreshCounter={setRefreshCounter}
      />
    </>
  );
};

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}

export default JobSearchActivity;

function LoadingDialog({ open }) {
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent>
        <Box pt={1} pl={6} pr={6} pb={2}>
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
LoadingDialog.propTypes = {
  open: PropTypes.bool,
};
