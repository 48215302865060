import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Button, Menu, MenuItem, Box } from '@mui/material/';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function AddNewExperienceMenu({ addNewExperience, clickAddFromWallet }) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="large"
        sx={{ textTransform: 'none', minHeight: 42, fontSize: 14 }}
      >
        {intl.formatMessage({ id: 'experience.form.addExperience' })}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            addNewExperience();
          }}
          disableRipple
          id="employer-provider-add-experience-dropdown"
        >
          <AddCircleIcon />
          {intl.formatMessage({ id: 'experience.page.addNewExperience' })}
        </MenuItem>
        {false && (
          <MenuItem
            onClick={() => {
              trackEvent('EXPERIENCE_WALLET_ADD_LINK_CLICK');
              handleClose();
              clickAddFromWallet();
            }}
            disableRipple
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <OpenInNewIcon />
            <a
              href="velocity-network-testnet://inspect?request_uri=https%3A%2F%2Fstagingsaasagent.velocitycareerlabs.io%2Fapi%2Fholder%2Fv0.6%2Forg%2Fdid%3Aion%3AEiDd-ociACK9eG_VNHoRPlw08lDP1ZskI_I5e5rJ8EnY4w%2Finspect%2Fget-presentation-request%3Fid%3D6591d665ff478a9f204e9b3b&inspectorDid=did%3Aion%3AEiDd-ociACK9eG_VNHoRPlw08lDP1ZskI_I5e5rJ8EnY4w"
              style={{ display: 'block', textDecoration: 'none', color: 'rgb(55, 65, 81)', textWrap: 'initial' }}
            >
              {intl.formatMessage({ id: 'experience.page.shareFromWallet' })}
            </a>
          </MenuItem>
        )}
      </StyledMenu>
    </Box>
  );
}

AddNewExperienceMenu.propTypes = {
  addNewExperience: PropTypes.func,
  clickAddFromWallet: PropTypes.func,
};
