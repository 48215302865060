import { createSlice } from '@reduxjs/toolkit';

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    results: {},
    currentSoc: '',
    activeSoc: '',
    activeCareer: {},
    filterByNameValue: '',
    careerPathsFilterValues: [],
    skillsFilter: [],
    employerFilter: [],
    salaryRangesFilter: [],
    locationsFilterValues: [],
    sortBy: '',
    sortAsc: false,
    initialCareersFilterSet: false,
    showRecommendedJobs: true,
    filterOptions: {
      locations: [],
      careerPaths: [],
      orgs: [],
      skills: [],
    },
  },
  reducers: {
    setNameFilterValue: (state, action) => {
      state.filterByNameValue = action.payload;
    },
    setCareerPathsFilterValues: (state, action) => {
      state.careerPathsFilterValues = action.payload;
    },
    setSkillsFilter: (state, action) => {
      state.skillsFilter = action.payload;
    },
    setEmployerFilter: (state, action) => {
      state.employerFilter = action.payload;
    },
    setSalaryRangesFilter: (state, action) => {
      state.salaryRangesFilter = action.payload;
    },
    setLocationsFilterValues: (state, action) => {
      state.locationsFilterValues = action.payload;
    },
    setJobs: (state, action) => {
      state.results = action.payload;
    },
    setActiveSoc: (state, action) => {
      state.activeSoc = action.payload;
    },
    setActiveCareer: (state, action) => {
      state.activeCareer = action.payload;
    },
    setCurrentSoc: (state, action) => {
      state.currentSoc = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSortAsc: (state, action) => {
      state.sortAsc = action.payload;
    },
    setShowRecommendedJobs: (state, action) => {
      state.showRecommendedJobs = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setInitialCareersFilterSet: (state, action) => {
      state.initialCareersFilterSet = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNameFilterValue,
  setCareerPathsFilterValues,
  setSkillsFilter,
  setEmployerFilter,
  setSalaryRangesFilter,
  setLocationsFilterValues,
  setJobs,
  setActiveSoc,
  setActiveCareer,
  setCurrentSoc,
  setSortBy,
  setSortAsc,
  setShowRecommendedJobs,
  setFilterOptions,
  setInitialCareersFilterSet,
} = jobsSlice.actions;

export default jobsSlice.reducer;
